import axios from 'axios';

const appInfo = (url) => 
{
    return axios.get('/api/store/info',
    {
        params: {url}
    });
}

const getReviews = (options) => 
{
    return axios.get('/api/store/get_reviews',
    {
        params: options
    });
}


export {
    appInfo,
    getReviews
}