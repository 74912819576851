
/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2020 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

const MathUtils = {

    gcd: function(a, b) 
    {
	    if (b === 0) return a;

	    return MathUtils.gcd(b, a % b) 
	},

	aspectRatio: function(a, b) 
	{
		a = a || 0;
    	b = b || 0;

    	if (a === 0 && b === 0) return '0:0';

		const gcd = MathUtils.gcd(a, b);

		return (a / gcd) + ':' + (b / gcd);
	}
}

export default MathUtils;