
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import NewApp from './components/NewApp';
import { appContext, removeApp } from '../../services/apps';
import Skeleton from 'react-loading-skeleton';
import utils from 'underscore';
import { Table, RatingDiff } from '../../components';

const columns = [
    {
        Header: '#',
        accessor: 'priority'
    },
    {
        Header: '',
        accessor: 'icon',
        size: 110,
        cellClass: "pe-3",
        disableSortBy: true
    },
    {
        Header: 'Game',
        accessor: 'displayName',
        cellClass: "pb-3",
        sortType: (rowA, rowB) => {
            return rowA.original.name > rowB.original.name ? -1 : 1;
        }
    },
    {
        Header: 'Rating',
        accessor: 'rating',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original._rating > rowB.original._rating ? -1 : 1;
        }
        
    },
    {
        Header: 'Last Update',
        accessor: 'display_updated_at',
        sortType: (rowA, rowB, columnId, desc) => {
            const dateA = rowA.original.updated_at;
            const dateB= rowB.original.updated_at;
            return (dateA>dateB)-(dateA<dateB)
        }
        
    },
    {
        Header: 'Remove',
        accessor: 'actions',
        disableSortBy: true,
        size: 150
    }
];

const AppList = (props) => {

	let { apps } = useContext(appContext);

	const history = useHistory();

	const _remove = (e, id) => 
	{
		e.stopPropagation();

		utils.confirm('Are you sure you want to remove the application?<br>You can restore it anytime just by adding this app again.', (yes) => 
		{
			if (yes) 
			{
				utils.popup('removing');
				removeApp(id).then(() => utils.popup('hide')).catch(utils.hintError);
			}
		});
	}

	const data = useMemo(() => {

        if (!apps) return [];

        return apps.map((app, i) => {

        	const { _id, name, icon, ios, updated_at, packageName, priority, rating } = app;
        	
        	let displayDate = utils.date(updated_at, 'str');

        	return {
	            id: _id,
	            priority: priority,
	            icon: <AppIcon src={icon} ios={ios} />,
	            globalFilter: name + displayDate + packageName,
	            name: name,
	            rating: <span className="fs-5">{rating.toFixed(1)} <RatingDiff app={app} /></span>,
	            _rating: rating,
	            displayName: <>
	            		<h6 className="fw-bold">{name}</h6>
	               		<div className="app-list-subtitle text-muted">
	               			{
	               				ios ? 
	               				<span><i className="fab fa-app-store-ios" /> &nbsp;Apple App Store</span> :
								<span><i className="fab fa-google-play" /> &nbsp;Google Play Store</span>
	               				}
	               			&nbsp;&nbsp;|&nbsp; Bundle ID: {packageName}
	               		</div>
	               	</>,
	            display_updated_at: displayDate,
	            updated_at: new Date(updated_at),
	            actions: <button onClick={(e) => _remove(e, _id)} className="btn px-5 pt-3"><i className="fas fa-times"></i></button>
	        }
        });

    }, [apps]);

	return (
	    <>
	        <div className="d-flex align-items-center justify-content-between mb-4">
	            <h3 className="h3 mb-0 text-gray-800">Apps</h3>
	            <NewApp />
	        </div>

			{
				data.length > 0 && <Table sortBy={[{id: "priority", desc: false}]} onClick={(e) => {history.push("/apps/" + e.id)}} columns={columns} data={data} className="table-hover" />
			}

			{ !!apps && data.length === 0 && <div className="text-muted"> Apps list is empty </div> }

			{
                !apps && <div className="mt-4">
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={50} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={50} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={50} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={50} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={50} />
                </div>
            }
	    </>
	);
}

const AppIcon = props => {
	return (
		<div className="app-icon-container">

		    <img alt="App icon" src={props.src} /> 
		    {
	       		props.ios ? 
	       		<i className="fab fa-apple ios"></i> :
				<i className="fab fa-android android"></i>
			} 
		</div>
	);
}

export default AppList;