
import React from 'react';

const StarRating = (props) => (

	<div className="star-rating">
		<div className="stars" style={{width: (props.value / 5 * 100) + "%"}}></div>
	</div>

);

export { StarRating };