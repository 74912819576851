/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import Utils from './Utils';
import Loader from './Loader';

function ext(path) 
{
	return path.split('.').pop().toLowerCase();
}

const $ = window.$;

const images = ['jpg', 'png', 'gif', 'webp', 'jpeg'];
const videos = ['mp4', 'webm'];
const playables = ['html'];

const getType = function(_ext) 
{
	let type = null;

	if (videos.indexOf(_ext) > -1) type = 'video';
	else if (images.indexOf(_ext) > -1) type = 'image';
	else if (playables.indexOf(_ext) > -1) type = 'playable';
	else type = 'file';

	return type;
} 

const _PreviewContent = function(type, src, title) 
{
	const el = $('#asset_preview_modal');
	const content = Utils.el('asset_preview_modal_content');

	if (type === 'image') 
	{
		content.innerHTML = `<img title="${title || 'Asset Preview'}" alt="Image Asset Preview" src="${src}" />`;
	}
	else if (type === 'video') 
	{
		content.innerHTML = `<video title="${title || 'Asset Preview'}" controls src="${src}" />`;
	}
	else if (type === 'playable') 
	{
		content.innerHTML = `<iframe src="${src}" />`;
	}
	else 
	{
		content.innerHTML = `<h3 class="text-white">Cannot preview the asset</h3>`;
	}

	el.one('hidden.bs.modal', function (e) 
	{
		content.innerHTML = '';
	})

	el.modal('show');
}

const Preview = function(object) 
{
	let link = null;
	let type = null;
	let title = null;

	if (object instanceof File) 
	{
		Loader.file(object, function(base64) 
		{
			const extension = base64.split(';')[0].split('/')[1];

			type = getType(extension);
			
			_PreviewContent(type, base64);
		});
	}
	else 
	{
		if (typeof object === 'object') 
		{
			title = object.title;
		}

		link = Utils.assetPath(object);

		if (link) 
		{
			const extension = ext(link);

			type = getType(extension);

			_PreviewContent(type, link, title);
		}
	}
}

export default Preview;