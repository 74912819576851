import { CountriesObj, Languages } from "../consts";

const $ = window.$;

function betweenCaseSensitive(str1, str2) {
  return new RegExp("(?:" + str1 + ")(.*?)(?:" + str2 + ")", "g");
}

function betweenCaseInsensitive(str1, str2) {
  return new RegExp("(?:" + str1 + ")(.*?)(?:" + str2 + ")", "ig");
}

const Utils = {
  avgRating: (histogram) => {
    return (
      (1 * histogram["1"] +
        2 * histogram["2"] +
        3 * histogram["3"] +
        4 * histogram["4"] +
        5 * histogram["5"]) /
      (histogram["1"] +
        histogram["2"] +
        histogram["3"] +
        histogram["4"] +
        histogram["5"])
    );
  },
  countryName: function (code) {
    return CountriesObj[code] || code;
  },

  diffPercentageIncrease: function (A, B) {
    // return (A / B - 1) * 100; the same
    return ((A - B) / B) * 100;
  },

  languageName: function (code) {
    return Languages[code] || code;
  },

  countryFlag: function (code) {
    return (
      "https://cdn.jsdelivr.net/gh/hjnilsson/country-flags@latest/svg/" +
      code.toLowerCase() +
      ".svg"
    );
  },

  is: function (percent) {
    percent = percent || 0.5;

    return Math.random() < percent;
  },

  remove: function (arr, predicate) {
    const index = arr.findIndex(predicate);

    if (index > -1) return arr.splice(index, 1)[0];

    return [];
  },

  KBtoStr: function (size) {
    if (size > 1000) {
      return (size / 1024).toFixed(2) + "Mb.";
    } else {
      return size + "Kb.";
    }
  },

  load: function (loading) {
    if (loading) {
      $("#loadingPage").show();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
      $("#loadingPage").fadeOut(500);
    }
  },

  clone: function (obj) {
    return JSON.parse(JSON.stringify(obj));
  },

  fileName: function (path, keepExtension) {
    var res = path.substring(path.lastIndexOf("/") + 1);

    if (!keepExtension) {
      res = res.split(".");
      res.length > 1 && res.pop();
      res = res.join(".");
    }

    return res;
  },

  calcKPIs: function (metrics, prettyCalc) {
    const result = {
      spend: metrics.spend,
      cpi: metrics.spend / metrics.installs,
      ctr: (metrics.clicks / metrics.impressions) * 100,
      ipm: (metrics.installs / metrics.impressions) * 1000,
      ir: (metrics.installs / metrics.clicks) * 100,
      cpm: (metrics.spend / metrics.impressions) * 1000,
      ecpm: metrics.spend / metrics.impressions,
    };

    if (prettyCalc) {
      result.spend = result.spend.toFixed(2) + "$";
      result.cpi = result.cpi.toFixed(2) + "$";
      result.ctr = result.ctr.toFixed(2) + "%";
      result.ipm = result.ipm.toFixed(2) + "%";
      result.ir = result.ir.toFixed(2) + "%";
      result.cpm = result.cpm.toFixed(2) + "$";
      result.ecpm = result.ecpm.toFixed(2) + "$";
    }

    return result;
  },

  getDate: function (date) {
    return date.toISOString().split("T")[0];
  },

  formatDate: function (_d, format) {
    let dd = _d.getDate();
    let mm = _d.getMonth() + 1;
    let yyyy = _d.getFullYear();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return format.replace("yyyy", yyyy).replace("mm", mm).replace("dd", dd);
  },

  getDateRange: function (from, to) {
    from = new Date(from);
    to = new Date(to);

    if (from.getFullYear() !== to.getFullYear()) {
      return (
        this.formatDate(from, "yyyy/mm/dd") +
        " - " +
        this.formatDate(to, "yyyy/mm/dd")
      );
    }

    if (from.getMonth() !== to.getMonth()) {
      let str = from.getFullYear();

      return (
        str +
        " " +
        this.formatDate(from, "mm/dd") +
        " - " +
        this.formatDate(to, "mm/dd")
      );
    }

    if (from.getDate() !== to.getDate()) {
      let str = this.formatDate(from, "yyyy/mm");

      return (
        str +
        " " +
        this.formatDate(from, "dd") +
        " - " +
        this.formatDate(to, "dd")
      );
    }

    return this.formatDate(from, "yyyy/mm/dd");
  },

  getParam: function (campaign, network, key, defaultValue) {
    const typeOf = typeof defaultValue;

    if (typeOf === "object") {
      if (
        campaign[network] &&
        campaign[network][key] &&
        campaign[network][key].length > 0
      ) {
        defaultValue = campaign[network][key];
      } else if (campaign[key] && campaign[key].length > 0) {
        defaultValue = campaign[key];
      }
    } else {
      if (campaign[network] && campaign[network][key] !== undefined) {
        defaultValue = campaign[network][key];
      } else if (campaign[key] !== undefined) {
        defaultValue = campaign[key];
      }
    }

    if (typeOf === "string") {
      return "" + defaultValue;
    } else if (typeOf === "number") {
      return Number(defaultValue);
    } else {
      return defaultValue;
    }
  },

  inputToNum: function (e) {
    return {
      target: {
        name: e.target.name,
        value: e.target.value ? Number(e.target.value) : void 0,
      },
    };
  },

  assetPath: function (asset) {
    if (typeof asset === "object") asset = asset.path;

    if (asset.startsWith("http")) return asset;

    if (asset.indexOf("hosting/") > -1) return asset;

    return "/hosting/" + asset;
  },

  hide: function (id) {
    $("#" + id).hide();
  },

  show: function (id) {
    $("#" + id).show();
  },

  el: function (id) {
    return document.getElementById(id);
  },

  ev: function (id) {
    return Utils.el(id).value;
  },

  substr2: function (str, str1, str2) {
    let test = betweenCaseInsensitive(str1, str2).exec(str);

    if (test && test.length > 1) {
      return test[1];
    }

    return null;
  },

  substr: function (str, str1, str2) {
    let test = betweenCaseSensitive(str1, str2).exec(str);

    if (test && test.length > 1) {
      return test[1];
    }

    return null;
  },

  pushLimit: function (array, element, limit, front) {
    if (front === true) {
      array.splice(0, 0, element);
      array.splice(limit, array.length - limit);
    } else {
      array.push(element);
      array.splice(0, array.length - limit);
    }
  },

  popupCenter: function (url, options) {
    options = options || {};

    let { title, w, h } = options;

    w = w || window.screen.width * 0.6;
    h = h || window.screen.height * 0.6;

    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;

    const newWindow = window.open(
      url,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );

    if (window.focus) newWindow.focus();

    return newWindow;
  },
};

export default Utils;
