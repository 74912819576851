
import { useMemo, useState, useCallback, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import utils from 'underscore';
import { Table, StarRating } from '../../components';
import { CountriesSelect }  from '../../components/select';
import { getReviews } from '../../services/store';
import { CountriesObj } from '../../consts';

const allowedCountries=['US', 'AU', 'CA', 'CN', 'FR', 'DE', 'GB', 'IT', 'JP', 'KR', 'RU', 'DZ', 'AO', 'AR', 'AT', 'AZ', 'BH', 'BB', 'BY', 'BE', 'BM', 'BR', 'BG', 'CL', 'CO', 'CR', 'HR', 'CY', 'CZ', 'DK', 'DO', 'EC', 'EG', 'SV', 'FI', 'GH', 'GR', 'GT', 'HK', 'HU', 'IN', 'ID', 'IE', 'IL', 'KZ', 'KE', 'KW', 'LB', 'LT', 'LU', 'MO', 'MG', 'MY', 'MT', 'MX', 'NL', 'NZ', 'NG', 'NO', 'OM', 'PK', 'PA', 'PE', 'PH', 'PL', 'PT', 'QA', 'RO', 'SA', 'RS', 'SG', 'SK', 'SI', 'ZA', 'ES', 'LK', 'SE', 'CH', 'TW', 'TH', 'TN', 'TR', 'UA', 'AE', 'UY', 'UZ', 'VE', 'VN', 'BO', 'KH', 'EE', 'LV', 'NI', 'PY', 'AF', 'GE', 'IQ', 'LY', 'MA', 'MZ', 'MM', 'YE']

const columns = [
    {
        Header: 'Country',
        accessor: 'country'
    },
    {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'text-nowrap',
        sortType: (rowA, rowB, columnId, desc) => {
            const dateA = rowA.original.date;
            const dateB= rowB.original.date;
            return (dateA>dateB)-(dateA<dateB)
        }
    },
    {
        Header: 'Rating',
        accessor: 'rating',
        sortType: (rowA, rowB) => {
            return rowA.original.rating > rowB.original.rating ? -1 : 1;
        }
    },
    {
        Header: 'Review',
        accessor: 'review',
        disableSortBy: true
    },
    {
        Header: 'Version',
        accessor: 'version'
    }
];

const Reviews = (props) => {

	const { app_id } = props;
	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [ratingFilter, setRatingFilter] = useState('all');
	const [countryFilter, setCountryFilter] = useState();
	const fetchIdRef = useRef(0);

	const endDate = new Date();
	const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 90);

	const [startDateFilter, setStartDateFilter] = useState(utils.date(startDate, 'date'));
	const [endDateFilter, setEndDateFilter] = useState(utils.date(endDate, 'date'));

	const data = useMemo(() => {

        return reviews.map((review, i) => {

        	const { content, version, date, rating, username, title, country, tags } = review;
        	
        	let displayDate = utils.date(date, 'date');

        	return {
	            globalFilter: content + username + title,
	            country: <div className="small text-nowrap"><img alt="Country flag" className="me-1" width="20" src={utils.countryFlag(country)}/> {CountriesObj[country] || ""} </div>,
	            rating: <StarRating value={rating} />,
	            review: <>
	            		<div>
	            			{title && <span className="fw-bold me-2">{title}</span>}
	            			<span className="small text-muted fst-italic">by {username}</span>
	            		</div>
	               		<div className="mt-1 review-content">
	               			{content}
	               		</div>
	               		{ 
	               			tags.length > 0 && <div className="mt-2">
			               		{tags.map(e => <span key={e} className="mx-1 badge bg-dark">{e}</span>)}
		            		</div>
		            	}
	               	</>,
	            date: displayDate,
	            _date: new Date(date),
	            version: version
	        }
        });

    }, [reviews]);

	const fetchData = useCallback((pageIndex, pageSize, search, sortBy) => {

		const fetchId = ++fetchIdRef.current;
		setLoading(true);

		let rating = ratingFilter;

		if (ratingFilter === 'all') rating = undefined;
		else if (ratingFilter === 'negative' || ratingFilter === 'positive') rating = ratingFilter;
		else rating = Number(ratingFilter);

		if (startDateFilter && endDateFilter) 
		{
			let startDate = new Date(startDateFilter);
			if (startDate > new Date(endDateFilter)) 
			{

			}
		}

		const options = {
			app_id: app_id,
			page: pageIndex + 1,
			limit: pageSize,
			rating: rating,
			country: countryFilter,
			start_date: startDateFilter,
			end_date: endDateFilter,
			search_term: search
		}

		if (sortBy.length > 0) 
		{
			options.sort_by = sortBy[0].id;
			options.sort_order = sortBy[0].desc ? 'desc' : 'asc';
		}

		getReviews(options).then((result) => 
		{
			if (fetchId === fetchIdRef.current) 
			{
				setReviews(result.feedback);

				// setPageCount(result.page_count);
				setPageCount(Math.ceil(result.total_count / pageSize));
				setTotalCount(result.total_count);

				setLoading(false);
			}
		}).catch(utils.hintError);

	}, [app_id, ratingFilter, countryFilter, startDateFilter, endDateFilter]);

	const setDateChanged = (e) => 
	{
		const name = e.target.name;
		const value = e.target.value;

		if (name === 'start') 
		{
			setStartDateFilter(value);

			if (new Date(value) > new Date(endDateFilter)) 
			{
				setEndDateFilter(value);
			}
		}
		else 
		{
			setEndDateFilter(value);

			if (new Date(startDateFilter) > new Date(value)) 
			{
				setStartDateFilter(value);
			}
		}
	}

	return (
	    <>
	        <div className="card mb-4 mt-4 shadow-sm">
	        	<div className="card-header fw-bold">
	        		Filter
	        	</div>
	        	<div className="card-body">
	        		<div className="row align-items-end">
	        			<div className="col-sm-12 col-md-6 col-lg-3 my-1">
		        			<label className="mb-1">Rating: </label>
							<select value={ratingFilter} onChange={(e) => setRatingFilter(e.target.value)} className="form-select" aria-label="Rating filter">
								<option value="all">All Reviews</option>
								<option disabled>------------</option>
								<option value="positive">All Positive Reviews</option>
								<option value="negative">All Negative Reviews</option>
								<option disabled>------------</option>
	
								<option value="5">5 Star ★★★★★</option>
								<option value="4">4 Star ★★★★</option>
								<option value="3">3 Star ★★★</option>
								<option value="2">2 Star ★★</option>
								<option value="1">1 Star ★</option>
							</select>
		        		</div>
		        		<div className="col-sm-12 col-md-6 col-lg-3 my-1">
		        			<label className="mb-1">Country: </label>
		        			<CountriesSelect allowedCountries={allowedCountries} onChange={setCountryFilter} />
		        		</div>
		        		<div className="col-sm-12 col-md-6 col-lg-3 my-1">
		        			<label htmlFor="reviewFilter-startDate" className="mb-1">Start Date: </label>
		        			<input name="start" onChange={setDateChanged} type="date" value={startDateFilter} className="form-control" id="reviewFilter-startDate" />
		        		</div>
		        		<div className="col-sm-12 col-md-6 col-lg-3 my-1">
		        			<label htmlFor="reviewFilter-endDate" className="mb-1">End Date: </label>
		        			<input name="end" onChange={setDateChanged} type="date" value={endDateFilter} className="form-control" id="reviewFilter-endDate" />
		        		</div>
	        		</div>
	        	</div>
	        </div>

			{
				<Table 
					pageSize={20}
					fetchData={fetchData}
					loading={loading}
        			pageCount={pageCount}
        			totalCount={totalCount}
					columns={columns}
					data={data}
					sortBy={[{id: "date", desc: true}]}
					className="table-striped"
				/>
			}

			{ !loading && data.length === 0 && <div className="text-muted"> Reviews list is empty </div> }

			{
                loading && data.length === 0 && <div className="mt-4">
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                </div>
            }
	    </>
	);
}

export default Reviews;