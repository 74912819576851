
import underscore from 'underscore';
import Utils from './Utils';
import Loader from './Loader';
import Popup from './Popup';
import Hint from './Hint';
import MathUtils from './MathUtils';
import Preview from './Preview';
import Confirm from './Confirm';
import Promt from './Promt';
import Alert from './Alert';
import Media from './Media';
import Strings from './Strings';
import DateUtils from './DateUtils';

Object.assign(underscore, Utils);

Object.assign(underscore, {
	math: MathUtils,
	load: Loader,
	popup: Popup,
	hint: Hint,
	preview: Preview,
	confirm: Confirm,
	promt: Promt,
	alert: Alert,
	media: Media,
	str: Strings,
	date: DateUtils,
	hintError: function(err) 
	{
		Hint(err, {alert: 'danger'})
	}
});

// underscore.mixin(Utils);
// underscore.mixin(Utilities);
// console.log(underscore.math)

export default underscore;