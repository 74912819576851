/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import utils from "underscore";

import Sidebar from "./partials/Sidebar";
import Topbar from "./partials/Topbar";
import Footer from "./partials/Footer";

import LoadingPage from "./pages/Loading";

import BlankPage from "./pages/Blank";
import NotFoundPage from "./pages/NotFound";

import AppsListPage from "./pages/Apps/List";
import AppDetailsPage from "./pages/Apps/Details";
import NotificationsSettings from "./pages/Notifications";

import AdminPage from "./admin/blank";

import Modals from "./modals";

import { ScrollToTop } from "./components";

import { getProfile, userContext, userEvents } from "./services/user";

import { organizationContext } from "./services/organization";

import { getApps, appContext, appEvents } from "./services/apps";

let requestProfileData = window.location.pathname !== "/provider/logout";

const mql = window.matchMedia(`(min-width: 800px)`);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      members: [],
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      loading: requestProfileData,
      loggedIn: false,
      userservices: [],
      apps: null,
      app: null,
    };

    this.setSidebarOpen = this.setSidebarOpen.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toogleSidebar = this.toogleSidebar.bind(this);
    this.setApp = this.setApp.bind(this);
  }

  setApp(app) {
    this.setState({ app });
  }

  setSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  toogleSidebar(open) {
    this.setSidebarOpen(!this.state.sidebarOpen);
  }

  updateProfile() {
    // this.setState({user: profile});
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);
    appEvents.onupdate = () => this.fetchApps();

    if (requestProfileData) {
      getProfile()
        .then((profile) => {
          this.setState({
            user: profile,
            loggedIn: true,
          });

          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 200);
        })
        .catch(() => {
          window.localStorage.removeItem("userStatus");
        });
    }

    userEvents.onprofileupdate = () => {
      getProfile().then((profile) => {
        this.setState({ user: profile });
      });
    };
  }

  fetchApps() {
    getApps()
      .then((apps) => {
        this.setState({ apps });
      })
      .catch(utils.hintError);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.user !== undefined &&
      prevState.user !== this.state.user &&
      this.state.user.email
    ) {
      if (prevState.user.id === this.state.user.id) {
        return;
      }

      this.fetchApps();
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingPage />;
    }

    return (
      <Router>
        <userContext.Provider value={this.state.user}>
          <organizationContext.Provider value={{ members: this.state.members }}>
            <appContext.Provider
              value={{
                apps: this.state.apps,
                setApp: this.setApp,
                app: this.state.app,
              }}
            >
              <Sidebar
                docked={this.state.sidebarDocked}
                open={this.state.sidebarOpen}
                onSetOpen={this.setSidebarOpen}
              >
                <div id="wrapper">
                  <Topbar
                    sidebarDocked={this.state.sidebarDocked}
                    toogleSidebar={this.toogleSidebar}
                  />
                  <div className="wrapper-content pb-3 px-4 pt-4 bg-gray-100">
                    <Switch>
                      <Route exact path="/blank" component={BlankPage} />
                      <Route exact path="/apps" component={AppsListPage} />
                      <Route
                        exact
                        path="/apps/:appId"
                        component={AppDetailsPage}
                      />
                      <Route
                        exact
                        path="/notifications"
                        component={NotificationsSettings}
                      />
                      <Route exact path="/" component={AppsListPage} />

                      {this.state.user.lvl > 1 && (
                        <Route exact path="/admin" component={AdminPage} />
                      )}

                      <Route path="*" component={NotFoundPage} />
                    </Switch>
                  </div>

                  <Footer />
                </div>
              </Sidebar>

              <ScrollToTop />

              <Modals />
            </appContext.Provider>
          </organizationContext.Provider>
        </userContext.Provider>
      </Router>
    );
  }
}

export default App;
