import axios from "axios";

const getRules = (appId) => {
  return axios.get("/api/alerts/list/" + appId);
};

const addRule = (data) => {
  return axios.post("/api/alerts", data);
};

const updateRule = (data) => {
  return axios.put("/api/alerts/" + data._id, data);
};

const removeRule = (id) => {
  return axios.delete("/api/alerts/" + id);
};

export { getRules, addRule, updateRule, removeRule };
