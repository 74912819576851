
const ServiceCategories = {
    'acquisition': 'Acquisition',
    'monetization': 'Monetization',
    'business': 'Business',
    'graphics': 'Art & Design',
    'data': 'Analytics',
    'hr': 'HR',
    'other': 'Other'
}

export { ServiceCategories };