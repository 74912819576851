/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect } from "react";
import Sidebar from "react-sidebar";
import { Link, useHistory } from "react-router-dom";

const AppSidebar = (props) => {

    const history = useHistory();

    const onSetOpen = props.onSetOpen;

    useEffect(() => 
    {
        return history.listen(() => 
        {
            onSetOpen(false);
        });

    }, [history, onSetOpen]);

    return (
        <Sidebar
            sidebar={
                <ul className="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion" id="accordionSidebar">

                    <div onClick={() => {history.push('/')}} role="button" className="brand text-light mt-2">
                        <div className="text-logo">QC</div>
                        <span className="ms-1 h4"> Dashboard </span>
                    </div>

                    <hr className="sidebar-divider my-0"/>

                    <li className="nav-item">
                        <Link className="nav-link" to="/">
                            <i className="fas fa-fw fa-home"></i>&nbsp;
                            <span>Games</span></Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/notifications">
                            <i className="fas fa-fw fa-bell"></i>&nbsp;
                            <span>Notifications</span></Link>
                    </li>

                    <hr className="sidebar-divider"/>

                </ul>
            }

            open={props.open}
            
            docked={props.docked}

            onSetOpen={props.onSetOpen}

            sidebarClassName="sidebar-parent"

            contentId="page-content"
        >
            { props.children }
        </Sidebar>
    );
}
 
export default AppSidebar;