import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import utils from "underscore";
import {
  getRules,
  addRule,
  updateRule,
  removeRule,
} from "../../services/rules";
import { Input } from "../../components";

const freqOptions = {
  24: "24h",
  48: "48h",
  72: "3d",
  168: "7d",
};

const Rules = (props) => {
  const { app_id } = props;
  const [rules, setRules] = useState(null);
  const [editingCondition, setEditingCondition] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    getRules(app_id)
      .then((rules) => {
        setRules(rules);
      })
      .catch(utils.hintError);
  }, [app_id]);

  useEffect(() => {
    if (rules) {
      document.getElementById("alerts-tab").innerHTML =
        'Alerts configuration <span class="ms-1 badge bg-danger">' +
        rules.length +
        "</span>";
    }
  }, [rules]);

  const setAdding = () => {
    setIsEditing(false);
    setEditingCondition({
      1: true,
      2: false,
      3: false,
      4: false,
      5: false,
      value: 3,
      frequency: "24",
    });
  };

  const onEdit = (data) => {
    setIsEditing(data._id);
    setEditingCondition(data.condition);

    setTimeout(() => {
      const htmlEl = document.getElementById("alerts-header");
      htmlEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 200);
  };

  const onRemove = (id) => {
    setEditingCondition(null);

    utils.confirm("Are you sure you want to remove this condition?", (yes) => {
      if (yes) {
        utils.popup("removing");
        removeRule(id)
          .then(() => {
            utils.remove(rules, (e) => e._id === id);
            setRules(rules.slice());
          })
          .catch(utils.hintError);
      }
    });
  };

  const addNewRule = () => {
    if (!editingCondition.value)
      return utils.hintError("Condition value should be > 0");
    if (
      !(
        editingCondition["1"] ||
        editingCondition["2"] ||
        editingCondition["3"] ||
        editingCondition["4"] ||
        editingCondition["5"]
      )
    )
      return utils.hintError(
        "At least one rating score should be selected for Alert Condition."
      );

    const data = {
      condition: editingCondition,
    };

    if (isEditing) data._id = isEditing;
    else data.app = app_id;

    utils.popup("loading");

    const fn = isEditing ? updateRule : addRule;

    fn(data)
      .then((result) => {
        if (!isEditing) {
          rules.push(result);
        } else {
          const exists = rules.find((e) => e._id === isEditing);
          Object.assign(exists, data);
        }

        setRules(rules.slice());
        utils.popup("hide");
        setEditingCondition(null);
      })
      .catch(utils.hintError);
  };

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setEditingCondition({ ...editingCondition, [name]: value });
  };

  return (
    <>
      <div className="my-3 d-flex justify-content-end" id="alerts-header">
        <button
          onClick={setAdding}
          className="btn btn-sm btn-success shadow-sm"
        >
          <i className="fa fa-plus fa-sm me-1" /> Add New
        </button>
      </div>

      {editingCondition && (
        <div className="card">
          <div className="card-header">Alert Configuration</div>

          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-12 col-sm-2 mb-2 fw-bold">
                Select Rating Alert:
              </div>
              <div className="col-12 col-sm-10">
                <div className="ms-2 form-check-inline">
                  <label className="me-2" htmlFor="config-alerts-rating-1">
                    1*
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="1"
                    id="config-alerts-rating-1"
                    onChange={handleChange}
                    checked={editingCondition["1"]}
                  />
                </div>
                <div className="ms-2 form-check-inline">
                  <label className="me-2" htmlFor="config-alerts-rating-2">
                    2*
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="2"
                    id="config-alerts-rating-2"
                    onChange={handleChange}
                    checked={editingCondition["2"]}
                  />
                </div>
                <div className="ms-2 form-check-inline">
                  <label className="me-2" htmlFor="config-alerts-rating-3">
                    3*
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="3"
                    id="config-alerts-rating-3"
                    onChange={handleChange}
                    checked={editingCondition["3"]}
                  />
                </div>
                <div className="ms-2 form-check-inline">
                  <label className="me-2" htmlFor="config-alerts-rating-4">
                    4*
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="4"
                    id="config-alerts-rating-4"
                    onChange={handleChange}
                    checked={editingCondition["4"]}
                  />
                </div>
                <div className="ms-2 form-check-inline">
                  <label className="me-2" htmlFor="config-alerts-rating-5">
                    5*
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="5"
                    id="config-alerts-rating-5"
                    onChange={handleChange}
                    checked={editingCondition["5"]}
                  />
                </div>
              </div>
            </div>

            <div className="row align-items-end mt-3">
              <div className="col-12 col-sm-2 fw-bold mb-2">
                {" "}
                Set Frequency:{" "}
              </div>
              <div className="col-sm-12 col-md-3 px-3 mb-2">
                <Input
                  type="number"
                  value={editingCondition.value}
                  name="value"
                  title="Ratings amount >"
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12 col-md-3 px-3 mb-2">
                <label className="mb-1">Time limit in</label>
                <select
                  onChange={handleChange}
                  name="frequency"
                  value={editingCondition.frequency}
                  className="form-select form-select-sm"
                >
                  <option value="24">24h</option>
                  <option value="48">48h</option>
                  <option value="72">3d</option>
                  <option value="168">7d</option>
                </select>
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-end mx-3 mb-3">
            <button
              onClick={() => setEditingCondition(null)}
              className="btn btn-sm btn-secondary"
            >
              Cancel
            </button>
            <button
              onClick={addNewRule}
              className="btn btn-sm btn-success shadow-sm ms-2"
            >
              <i className="fa fa-check fa-sm me-1" />{" "}
              {isEditing ? "Update" : "Add"} Alert
            </button>
          </div>
        </div>
      )}

      <hr />

      <h5>Existing Alerts</h5>

      {!!rules &&
        rules.length > 0 &&
        rules.map((e) => {
          return (
            <Rule key={e._id} value={e} onEdit={onEdit} onRemove={onRemove} />
          );
        })}

      {!!rules && rules.length === 0 && (
        <div className="text-muted mt-4"> Alerts list is empty </div>
      )}

      {rules === null && (
        <div className="mt-4">
          <Skeleton
            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
            width="100%"
            height={30}
          />
          <Skeleton
            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
            width="100%"
            height={30}
          />
          <Skeleton
            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
            width="100%"
            height={30}
          />
          <Skeleton
            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
            width="100%"
            height={30}
          />
          <Skeleton
            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
            width="100%"
            height={30}
          />
        </div>
      )}
    </>
  );
};

const Rule = (props) => {
  const condition = props.value.condition;

  const scores =
    ["1", "2", "3", "4", "5"].filter((e) => condition[e]).join("*, ") + "*";

  return (
    <div className="card mt-2">
      <div className="card-body d-flex align-items-center">
        <div>
          Alert when{" "}
          <span className="fw-bold">
            {condition.value} <span className="text-warning">({scores})</span>
          </span>{" "}
          will reach in{" "}
          <span className="fw-bold">{freqOptions[condition.frequency]} </span>
        </div>
        <button
          onClick={() => props.onEdit(props.value)}
          className="btn btn-sm btn-light ms-auto"
        >
          Edit
        </button>
        <button
          onClick={() => props.onRemove(props.value._id)}
          className="btn btn-sm btn-light text-danger ms-2"
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default Rules;
