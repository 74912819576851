import axios from "axios";

const getNotifications = (time) => {
  return axios.get("/api/notifications/list", { params: { time: time } });
};

const updateNotification = (id) => {
  return axios.patch("/api/notifications", { id: id });
};

export { getNotifications, updateNotification };
