import React from "react";

export const Message = ({
  icon,
  role,
  checked,
  onClick,
  message,
  created_at,
}) => {
  const daysPast = parseInt(
    (Date.now() - Date.parse(created_at)) / (1000 * 60 * 60 * 24)
  );
  return (
    <div
      style={{ backgroundColor: `${!checked ? "rgba(0, 0, 255, 0.05)" : ""}` }}
      role={role}
      onClick={onClick}
      className={"w-100 d-flex  dropdown-item  medium fs-6 gap-3"}
    >
      <i
        style={{ fontSize: "2.5em" }}
        className="d-flex align-items-center fa fa-exclamation-circle text-primary"
        aria-hidden="true"
      />
      <div className="d-flex align-items-center ">
        <img style={{ height: "3em", borderRadius: "3px" }} src={icon} />
      </div>

      <div className="d-flex flex-column gap-1">
        <div style={{ fontSize: "0.9em", color: "#C0C0C0" }}>
          {daysPast === 0 && "Today"}
          {daysPast === 1 && `${daysPast} day ago`}
          {daysPast > 1 && `${daysPast} days ago`}
        </div>
        <div className="align-self-center">{message}</div>
      </div>
    </div>
  );
};
