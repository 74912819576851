
import { useMemo } from 'react';
import Select from 'react-select';
import { Countries, CountriesObj } from '../../consts';

const CountriesSelect = (props) => 
{
    const { allowedCountries } = props;

    const options = useMemo(() => 
    {
        if (!!allowedCountries) 
        {
            return allowedCountries.map( e => { return {value: e, label: CountriesObj[e] } });
        }
        else 
        {
            return Countries.map( e => { return {value: e.code, label: e.name } });
        }

    }, [allowedCountries]);

    return (
        <Select
            defaultValue={options.find(e => e.value === props.defaultValue )}
            name="location"
            isDisabled={props.disabled}
            onChange={(e) => {
                props.onChange && props.onChange(e && e.value);
            }}
            options={options}
            placeholder="Choose Country"
            isClearable={true}
            className="basic-single"
            classNamePrefix="select"
        />
    )
}

export { CountriesSelect };