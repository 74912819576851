/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import profileIcon from "../img/undraw_profile.svg";
import { userContext } from "../services/user";
import utils from "underscore";
import {
  getNotifications,
  updateNotification,
} from "../services/notifications";
import { Message } from "./Message";
import { appContext } from "../services/apps";

const Topbar = (props) => {
  const [alerts, setAlerts] = useState([]);
  const [diff, setDiff] = useState(0);
  const listRef = useRef([]);
  const { apps } = useContext(appContext);
  const { email, name, picture, provider, role } = useContext(userContext);

  const fetchNotifications = (time) => {
    getNotifications(time).then((data) => {
      for (let n of data) {
        listRef.current.push(n);
      }
      setDiff(listRef.current.filter((v) => !v.read_at).length);
      setAlerts(listRef.current);
    });
  };
  useEffect(() => {
    fetchNotifications();
    const request = setInterval(
      () => fetchNotifications(listRef.current.at(-1)?.created_at),
      10000
    );
    return () => {
      clearInterval(request);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand navbar-light text-dark bg-white topbar sticky-top shadow px-3">
        {!props.sidebarDocked && (
          <button
            onClick={props.toogleSidebar}
            className="btn btn-link rounded-circle me-3"
          >
            <i className="fa fa-bars" />
          </button>
        )}

        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown no-arrow mx-1">
            <div
              className="nav-link dropdown-toggle"
              id="alertsDropdown"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-bell fa-fw"></i>

              <span className="badge bg-danger fw-bold badge-counter">
                {diff !== 0 && `+${diff}`}
              </span>
            </div>
            <div
              style={{ maxHeight: "300px" }}
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in overflow-auto"
              aria-labelledby="alertsDropdown"
            >
              <div className="d-flex justify-content-between dropdown-header">
                <div>Alerts Center</div>
                <div>
                  <div
                    role={"button"}
                    onClick={() => {
                      setDiff(0);
                      setAlerts((p) => {
                        p.forEach((v) => {
                          if (!v.read_at) {
                            updateNotification(v._id);
                            v.read_at = new Date();
                          }
                        });
                        return p;
                      });
                    }}
                  >
                    Mark all as read
                  </div>
                </div>
              </div>
              {!!alerts.length &&
                alerts.map((v) => {
                  return (
                    <Message
                      icon={apps.find((a) => v.message.includes(a.name)).icon}
                      role={"button"}
                      checked={v.read_at}
                      created_at={v.created_at}
                      key={v._id}
                      message={v.message}
                      onClick={() => {
                        if (!v.read_at) {
                          v.read_at = new Date();
                          setDiff(alerts.filter((v) => !v.read_at).length);
                          updateNotification(v._id);
                        }
                      }}
                    />
                  );
                })}
              {!alerts.length && (
                <div className="dropdown-item d-flex align-items-center justify-content-center" style={{color: "#C0C0C0"}}>
                  There are no notifications yet
                </div>
              )}
            </div>
          </li>

          <li className="nav-item mx-1 d-none d-sm-block">
            <a
              className="nav-link"
              target="_blank"
              rel="noreferrer"
              href="https://greenpandagames.atlassian.net/wiki/spaces/WEB/overview"
              id="alertsDropdown"
            >
              <i className="fa fa-book fa-fw"></i>
            </a>
          </li>

          <li className="nav-item dropdown no-arrow mx-1">
            <div
              className="nav-link dropdown-toggle"
              id="mobileMenuDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i style={{ fontSize: "22px" }} className="fas fa-ellipsis-h"></i>
            </div>
            <div
              className="dropdown-menu dropdown-menu-light dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="mobileMenuDropdown"
            >
              <a
                className="dropdown-item d-flex align-items-center d-sm-none"
                target="_blank"
                rel="noreferrer"
                href="https://greenpandagames.atlassian.net/wiki/spaces/WEB/overview"
              >
                <div className="me-3">
                  <i className="fa fa-book"></i>
                </div>
                <div>Tutorial</div>
              </a>
              <a
                className="dropdown-item d-flex align-items-center"
                target="_blank"
                rel="noreferrer"
                href="mailto:peter@greenpandagames.com"
              >
                <div className="me-3">
                  <i className="fa fa-comment"></i>
                </div>
                <div>Give Feedback</div>
              </a>
              <hr className="dropdown-divider" />
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() => {
                  utils.hint("Don't ready yet", { alert: "warning" });
                }}
              >
                <div className="me-3">
                  <i className="fas fa-comment-alt"></i>
                </div>
                <div>Chat</div>
              </button>

              {role === "admin" && (
                <>
                  <hr className="dropdown-divider" />

                  <Link
                    className="dropdown-item d-flex align-items-center"
                    rel="noreferrer"
                    to="/admin/service"
                  >
                    <div className="me-3">
                      <i className="fa fa-tools"></i>
                    </div>
                    <div>Services</div>
                  </Link>

                  <Link
                    className="dropdown-item d-flex align-items-center"
                    rel="noreferrer"
                    to="/admin/requests"
                  >
                    <div className="me-3">
                      <i className="fa fa-tools"></i>
                    </div>
                    <div>Requests</div>
                  </Link>

                  <Link
                    className="dropdown-item d-flex align-items-center"
                    rel="noreferrer"
                    to="/admin/userservice"
                  >
                    <div className="me-3">
                      <i className="fa fa-tools"></i>
                    </div>
                    <div>User Services</div>
                  </Link>
                </>
              )}
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <div
              className="nav-link dropdown-toggle"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="me-3 d-none d-sm-block small text-gray-600">
                <span>{name || email}</span>
              </div>
              <img
                className="img-profile rounded-circle"
                src={picture || profileIcon}
                alt=""
              />
            </div>
            <div
              className="dropdown-menu dropdown-menu-light dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href={provider + "/profile"}>
                <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>
                Profile
              </a>
              <a
                className="dropdown-item"
                href={provider + "/account/settings"}
              >
                <i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i>
                Settings
              </a>
              <a
                className="dropdown-item"
                href={provider + "/account/settings/password"}
              >
                <i className="fas fa-key fa-sm fa-fw me-2 text-gray-400"></i>
                Change Password
              </a>
              <a
                className="dropdown-item"
                href={provider + "/organization/members"}
              >
                <i className="fas fa-users fa-sm fa-fw me-2 text-gray-400"></i>
                Organization
              </a>
              <hr className="dropdown-divider" />
              <a
                className="dropdown-item"
                href="#logoutModal"
                data-bs-toggle="modal"
                data-bs-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Topbar;
