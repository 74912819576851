import { useEffect, useContext, createContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import utils from "underscore";

const appContext = createContext();

const appEvents = {};

let apps = null;

const getApps = () => {
  if (apps !== null) {
    return Promise.resolve(apps.slice());
  }

  return axios.get("/api/app/list").then((data) => {
    apps = data;
    return data;
  });
};

const getApp = (id) => {
  return axios.get("/api/app/" + id);
};

const removeApp = (id) => {
  return axios.delete("/api/app/" + id).then((res) => {
    utils.remove(apps, (e) => e._id === id);

    appEvents.onupdate && appEvents.onupdate();

    return res;
  });
};

const addOrUpdateApp = (info) => {
  return axios.post("/api/app", info).then((app) => {
    const found = apps.find((e) => e._id === app._id);

    if (!found) {
      apps.push(app);

      appEvents.onupdate && appEvents.onupdate();
    } else {
      Object.assign(found, app);
      appEvents.onupdate && appEvents.onupdate();
    }

    return app;
  });
};

function useApp() {
  const { app, setApp } = useContext(appContext);

  const { appId } = useParams();

  let _id = app && app.id;

  // console.log(_id);

  useEffect(() => {
    if (_id === appId) return;

    utils.popup("loading");

    getApp(appId)
      .then((app) => {
        utils.popup("hide");

        setApp(app);
      })
      .catch(utils.hintError);

    // return () =>
    // {
    //     setApp(null);
    // }
  }, [setApp, appId, _id]);

  return app;
}

export {
  useApp,
  appContext,
  appEvents,
  getApps,
  getApp,
  addOrUpdateApp,
  removeApp,
};
