/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';

const Blank = props => (
    <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Blank Page</h1>
            <a href="/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                    className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
        </div>

        <h1 className="h6 mb-4 text-gray-800">This is example page</h1>

        <div className="row">

            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col me-2">
                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Earnings (Monthly)</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col me-2">
                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Earnings (Annual)</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col me-2">
                                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                                </div>
                                <div className="row no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="h5 mb-0 me-3 font-weight-bold text-gray-800">50%</div>
                                    </div>
                                    <div className="col">
                                        <div className="progress progress-sm me-2">
                                            <div className="progress-bar bg-info" role="progressbar"
                                                style={{"width": "50%"}} aria-valuenow="50" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col me-2">
                                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    Pending Requests</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-comments fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">

            <div className="col-xl-8 col-lg-7">
                <div className="card shadow mb-4">
                    <div
                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">Earnings Overview</h6>
                        <div className="dropdown no-arrow">
                            <a className="dropdown-toggle" href="/" role="button" id="dropdownMenuLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                aria-labelledby="dropdownMenuLink">
                                <div className="dropdown-header">Dropdown Header:</div>
                                <a className="dropdown-item" href="/">Action</a>
                                <a className="dropdown-item" href="/">Another action</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="/">Something else here</a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="chart-area">
                            <canvas id="myAreaChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-4 col-lg-5">
                <div className="card shadow mb-4">
                    <div
                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">Revenue Sources</h6>
                        <div className="dropdown no-arrow">
                            <a className="dropdown-toggle" href="/" role="button" id="dropdownMenuLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                aria-labelledby="dropdownMenuLink">
                                <div className="dropdown-header">Dropdown Header:</div>
                                <a className="dropdown-item" href="/">Action</a>
                                <a className="dropdown-item" href="/">Another action</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="/">Something else here</a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="chart-pie pt-4 pb-2">
                            <canvas id="myPieChart"></canvas>
                        </div>
                        <div className="mt-4 text-center small">
                            <span className="me-2">
                                <i className="fas fa-circle text-primary"></i> Direct
                            </span>
                            <span className="me-2">
                                <i className="fas fa-circle text-success"></i> Social
                            </span>
                            <span className="me-2">
                                <i className="fas fa-circle text-info"></i> Referral
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">

            <div className="col-lg-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Projects</h6>
                    </div>
                    <div className="card-body">
                        <h4 className="small font-weight-bold">Server Migration <span
                                className="float-right">20%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar bg-danger" role="progressbar" style={{"width": "20%"}}
                                aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Sales Tracking <span
                                className="float-right">40%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar bg-warning" role="progressbar" style={{"width": "40%"}}
                                aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Customer Database <span
                                className="float-right">60%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar" role="progressbar" style={{"width": "60%"}}
                                aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Payout Details <span
                                className="float-right">80%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar bg-info" role="progressbar" style={{"width": "80%"}}
                                aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Account Setup <span
                                className="float-right">Complete!</span></h4>
                        <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{"width": "100%"}}
                                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-primary text-white shadow">
                            <div className="card-body">
                                Primary
                                <div className="text-white-50 small">#4e73df</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-success text-white shadow">
                            <div className="card-body">
                                Success
                                <div className="text-white-50 small">#1cc88a</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-info text-white shadow">
                            <div className="card-body">
                                Info
                                <div className="text-white-50 small">#36b9cc</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-warning text-white shadow">
                            <div className="card-body">
                                Warning
                                <div className="text-white-50 small">#f6c23e</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-danger text-white shadow">
                            <div className="card-body">
                                Danger
                                <div className="text-white-50 small">#e74a3b</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-secondary text-white shadow">
                            <div className="card-body">
                                Secondary
                                <div className="text-white-50 small">#858796</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-light text-black shadow">
                            <div className="card-body">
                                Light
                                <div className="text-black-50 small">#f8f9fc</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-dark text-white shadow">
                            <div className="card-body">
                                Dark
                                <div className="text-white-50 small">#5a5c69</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-lg-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Illustrations</h6>
                    </div>
                    <div className="card-body">
                        <div className="text-center">
                            <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{"width": "25rem"}}
                                src="img/undraw_posting_photo.svg" alt="" />
                        </div>
                        <p>Add some quality, svg illustrations to your project courtesy of <a
                                target="_blank" rel="noreferrer" href="https://undraw.co/">unDraw</a>, a
                            constantly updated collection of beautiful svg images that you can use
                            completely free and without attribution!</p>
                        <a target="_blank" rel="noreferrer" href="https://undraw.co/">Browse Illustrations on
                            unDraw &rarr;</a>
                    </div>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Development Approach</h6>
                    </div>
                    <div className="card-body">
                        <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classes in order to reduce
                            CSS bloat and poor page performance. Custom CSS classes are used to create
                            custom components and custom utility classes.</p>
                        <p className="mb-0">Before working with this theme, you should become familiar with the
                            Bootstrap framework, especially the utility classes.</p>
                    </div>
                </div>

            </div>
        </div>

        <div className="card">
	    <div className="card-header border-bottom">
	        <ul className="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
	            <li className="nav-item">
	                <a className="nav-link active" id="overview-tab" href="#overview" data-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
	            </li>
	            <li className="nav-item">
	                <a className="nav-link" id="example-tab" href="#example" data-toggle="tab" role="tab" aria-controls="example" aria-selected="false">Example</a>
	            </li>
	            <li className="nav-item">
	                <a className="nav-link disabled" href="/" tabIndex="-1" aria-disabled="true">Disabled</a>
	            </li>
	        </ul>
	    </div>
	    <div className="card-body">
	        <div className="tab-content" id="cardTabContent">
	            <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
	                <h5 className="card-title">Tabbed Navigation Card</h5>
	                <p className="card-text">...</p>
	            </div>
	            <div className="tab-pane fade" id="example" role="tabpanel" aria-labelledby="example-tab">
	                <h5 className="card-title">Example Pane</h5>
	                <p className="card-text">...</p>
	            </div>
	        </div>
	    </div>
	</div>
	<br/>
	<div className="card">
	    <div className="card-header">
	        <ul className="nav nav-pills card-header-pills" id="cardPill" role="tablist">
	            <li className="nav-item"><a className="nav-link active" id="overview-pill" href="#overviewPill" data-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Overview</a></li>
	            <li className="nav-item"><a className="nav-link" id="example-pill" href="#examplePill" data-toggle="tab" role="tab" aria-controls="example" aria-selected="false">Example</a></li>
	            <li className="nav-item"><a className="nav-link disabled" href="/" tabIndex="-1" aria-disabled="true">Disabled</a></li>
	        </ul>
	    </div>
	    <div className="card-body">
	        <div className="tab-content" id="cardPillContent">
	            <div className="tab-pane fade show active" id="overviewPill" role="tabpanel" aria-labelledby="overview-pill">
	                <h5 className="card-title">Pill Navigation Card</h5>
	                <p className="card-text">...</p>
	            </div>
	            <div className="tab-pane fade" id="examplePill" role="tabpanel" aria-labelledby="example-pill">
	                <h5 className="card-title">Example Pane</h5>
	                <p className="card-text">...</p>
	            </div>
	        </div>
	    </div>
	</div>
	<br/>
		<div className="card card-waves">
		    <div className="card-header">Waves Card Example</div>
		    <div className="card-body">...</div>
		</div>
		<br/>
		<button className="btn btn-primary" type="button" data-toggle="modal" data-target="#exampleModalCenter">Launch Vertically Centered Modal</button>
		<br/>
		<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		    <div className="modal-dialog modal-dialog-centered" role="document">
		        <div className="modal-content">
		            <div className="modal-header">
		                <h5 className="modal-title" id="exampleModalCenterTitle">Vertically Centered Modal</h5>
		                <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
		            </div>
		            <div className="modal-body">...</div>
		            <div className="modal-footer"><button className="btn btn-secondary" type="button" data-dismiss="modal">Close</button><button className="btn btn-primary" type="button">Save changes</button></div>
		        </div>
		    </div>
		</div>
		<br/>
		<div className="card card-progress">
		    <div className="card-header text-danger">25% Progress Card Example</div>
		    <div className="card-body">This is an example of a card with a 25% completed progress bar.</div>
		    <div className="progress rounded-0">
		        <div className="progress-bar bg-danger" role="progressbar" style={{"width": "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
		    </div>
		</div>
		<br/>
		<div className="card card-progress">
		    <div className="card-header text-warning">50% Progress Card Example</div>
		    <div className="card-body">This is an example of a card with a 50% completed progress bar.</div>
		    <div className="progress rounded-0">
		        <div className="progress-bar bg-warning" role="progressbar" style={{"width": "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
		    </div>
		</div>
		<br/>
		<div className="card card-progress">
		    <div className="card-header text-primary">75% Progress Card Example</div>
		    <div className="card-body">This is an example of a card with a 75% completed progress bar.</div>
		    <div className="progress rounded-0">
		        <div className="progress-bar bg-primary" role="progressbar" style={{"width": "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
		    </div>
		</div>
		<br/>
		<div className="card card-progress">
		    <div className="card-header text-success">100% Progress Card Example</div>
		    <div className="card-body">This is an example of a card with a 100% completed progress bar.</div>
		    <div className="progress rounded-0">
		        <div className="progress-bar bg-success" role="progressbar" style={{"width": "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
		    </div>
		</div>
		<br/>
		<div className="timeline">
	    <div className="timeline-item">
	        <div className="timeline-item-marker">
	            <div className="timeline-item-marker-text">01/01/2020</div>
	            <div className="timeline-item-marker-indicator bg-primary-soft text-primary"><i data-feather="flag"></i></div>
	        </div>
	        <div className="timeline-item-content pt-0">
	            <div className="card shadow-sm">
	                <div className="card-body">
	                    <h5 className="text-primary">Beginning</h5>
	                    This is the content for the first timeline item. In this styled example, we're styling the timeline marker with background and typography utility classes. We've also shown that you can use the card component within the timeline item content.
	                </div>
	            </div>
	        </div>
	    </div>
	    <div className="timeline-item">
	        <div className="timeline-item-marker">
	            <div className="timeline-item-marker-text">02/02/2020</div>
	            <div className="timeline-item-marker-indicator bg-success-soft text-success"><i data-feather="gift"></i></div>
	        </div>
	        <div className="timeline-item-content pt-0">
	            <div className="card shadow-sm">
	                <div className="card-body">
	                    <h5 className="text-success">Second Item</h5>
	                    This is the content for the second timeline item. In this timeline example, we're also using some spacing utilities on the timeline item content to remove the padding from the top of that element.
	                </div>
	            </div>
	        </div>
	    </div>
	    <div className="timeline-item">
	        <div className="timeline-item-marker">
	            <div className="timeline-item-marker-text">03/03/2020</div>
	            <div className="timeline-item-marker-indicator bg-secondary-soft text-secondary"><i data-feather="map"></i></div>
	        </div>
	        <div className="timeline-item-content pt-0">
	            <div className="card shadow-sm">
	                <div className="card-body">
	                    <h5 className="text-secondary">Third Item</h5>
	                    This is the content for the third timeline item. Keep in mind this is just an example of what you can put within the timeline item content. There are many other ways you can use this timeline, and it's fully responsive too!
	                </div>
	            </div>
	        </div>
	    </div>
	    <div className="timeline-item">
	        <div className="timeline-item-marker">
	            <div className="timeline-item-marker-text">04/04/2020</div>
	            <div className="timeline-item-marker-indicator bg-warning-soft text-warning"><i data-feather="send"></i></div>
	        </div>
	        <div className="timeline-item-content pt-0">
	            <div className="card shadow-sm">
	                <div className="card-body">
	                    <h5 className="text-warning">Last Item</h5>
	                    You can use different icons in the icon markers, letters, or no icons at all if you just want a blank circle.
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
	<br/>
	
    </>
);

export default Blank