
import React from 'react';

import Logout from './Logout';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import PromtModal from './PromtModal';
import Popup from './Popup';

const Modals = (props) => 
{
    return (
        <>
        	<Logout />
            <AlertModal />
            <ConfirmModal />
            <PromtModal />
            <Popup />
        </>
    );
};

export default Modals;