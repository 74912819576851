
import { format } from 'timeago.js';

function formatLocaleDate(str) {
    var index = str.indexOf(" (");

    if(index > -1) {
        return str.substr(0, index);
    } else 
        return  str
}

function addZero(i) 
{
    if (i < 10) 
    {
        i = "0" + i;
    }
    return i;
}

// function formatDate(_d) 
// {
//     let dd = _d.getDate();
//     let mm = _d.getMonth() + 1; 
//     let yyyy = _d.getFullYear();

//     if (dd < 10) dd = '0' + dd;
//     if (mm < 10) mm = '0' + mm;

//     return dd + '/' + mm + '/' + yyyy;
// }

function formatDate(date) 
    {
        const _d = new Date(date);
        
        let dd = _d.getDate();
        let mm = _d.getMonth() + 1; 
        let yyyy = _d.getFullYear();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    }

const date = function(src, _format) 
{
    if (!src) return 'Recently';

    var now = new Date();

    if (typeof src !== "object") 
    {
        src = new Date(src);
    }

    _format = _format || "locale";

    switch (_format) 
    {
        case "ago":
            return format(src);
        case "ago-sec":
            return Math.max(Math.round((now.getTime() - src.getTime()) / 1000), 0) + " seconds ago";
        case "gmt":
            return src.toGMTString();
        case "iso":
            return src.toISOString();
        case "date":
            return formatDate(src);
        case "time":
            return src.toLocaleTimeString();
        case "str-date":
            return src.toDateString();
        case "str":
            return formatLocaleDate(src.toString());
        case "str-short":
            return src.toDateString() + " " + addZero(src.getHours()) + ":" + addZero(src.getMinutes());
        case "locale":
            return src.toLocaleString();
        default:
            break;
    }

    var delta = now - src;

    if (_format === "ago-1") 
    {
        if (delta > 1000 * 60 * 60 * 24) 
        {
            return src.toLocaleString();
        }
        else 
        {
            return format(src);
        }
    }
    else if (_format === "ago-1-str") 
    {
        if (delta > 1000 * 60 * 60 * 24) 
        {
            return formatLocaleDate(src.toString());
        }
        else 
        {
            return format(src);
        }
    }
    else if (_format === "ago-1-short-str") 
    {
        if (delta > 1000 * 60 * 60 * 24) 
        {
            return src.toDateString() + " " + addZero(src.getHours()) + ":" + addZero(src.getMinutes());
        }
        else 
        {
            return format(src);
        }
    }
    else if (_format === "limit")
    {
        if (delta > 1000 * 60 * 60 * 24 || now.getDate() !== src.getDate()) 
        {
            return src.toLocaleString();
        }
        else 
        {
            return src.toLocaleTimeString()
        }
    }
    else if (_format === "limit-str")
    {
        if (delta > 1000 * 60 * 60 * 24 || now.getDate() !== src.getDate()) 
        {
            return formatLocaleDate(src.toString());
        }
        else 
        {
            return src.toLocaleTimeString()
        }
    }
    else if (_format === "ago-7")
    {
        if (delta > 1000 * 60 * 60 * 24 * 7) 
        {
            return src.toLocaleString();
        }
        else 
        {
            return format(src);
        }
    }
    else 
    {
        return src.toLocaleString();
    }
}

export default date;