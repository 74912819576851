/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import axios from 'axios';
import utils from 'underscore';

const organizationContext = React.createContext({members: []});

let members = null;

const getMembers = (email, password, password2) => 
{
    if (members) 
    {
        return Promise.resolve(members);
    }

    return axios.get('/api/organization/members').then((data) => {
        members = data;
        return data;
    });
}

const removeMember = (id) => 
{
    return axios.delete('/api/organization/member/' + id).then((res) => 
    {
    	if (members !== null) 
    	{
    		if (res.success) 
    		{
    			return utils.remove(members, e => e.id === id );
    		}
    		else 
    		{
    			let member = members.find( e => e.id === id );

    			if (member) member.removed_at = new Date();

    			return member;
    		}
    	}

    	return res;
    });
}

export { removeMember };

export { organizationContext };

export { getMembers };