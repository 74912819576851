
import React from 'react';
import Select from 'react-select';
import { ServiceCategories as List } from '../../consts';

const options = Object.keys(List).map( e => {return { value: e, label: List[e] }})

const ServiceCategories = (props) => 
{
    return (
        <Select
            defaultValue={options.find(e => e.value === props.defaultValue ) || undefined}
            value={options.find(e => e.value === props.value ) || undefined}
            name={props.name || "service-category"}
            isDisabled={props.disabled}
            onChange={(e) => {
                if (props.likeInput) 
                {
                    props.onChange && props.onChange({
                        target: {
                            name: props.name,
                            value: e.value
                        }
                    })
                }
                else 
                {
                    props.onChange && props.onChange(e.value);
                }
            }}
            options={options}
            placeholder="Choose Category"
            className="basic-single mb-2"
            classNamePrefix="select"
        />
    )
}

export { ServiceCategories };