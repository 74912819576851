
import React from 'react';

const Input = props => (
    <div className={props.className}>
        { props.title !== false && <label className="mb-1" htmlFor={props.id}>{props.title || props.name}</label> }
        <input name={props.name} disabled={props.disabled} defaultValue={props.defaultValue} onChange={props.onChange} type={props.type || "text"} value={props.value} className="form-control form-control-sm" placeholder={props.placeholder} id={props.id} />
    </div>
);

const Checkbox = props => (
    <div className="form-check">
        <input name={props.name} type="checkbox" className="form-check-input" onChange={props.onChange} id={props.name + props.title + props.id} defaultChecked={props.defaultValue} checked={props.value} />
        <label className="form-check-label" disabled={props.disabled} htmlFor={props.name + props.title + props.id}>{props.title || props.name}</label>
    </div>
);

const TextArea = props => (
    <div>
        <label className="mb-1" htmlFor={props.id}>{props.title || props.name}</label>
        <textarea name={props.name} rows={props.rows} disabled={props.disabled} defaultValue={props.defaultValue} onChange={props.onChange} type="text" value={props.value !== undefined ? props.value : ""} className="form-control" id={props.id} placeholder={props.placeholder} />
    </div>
);

const BaseTextArea = props => (
    <div>
        <label className="mb-1" htmlFor={props.id}>{props.title || props.name}</label>
        <textarea name={props.name} disabled={props.disabled} defaultValue={props.defaultValue} onChange={e => props.onChange(e.target.value)} type="text" value={props.value !== undefined ? props.value : ""} className="form-control" id={props.id} />
    </div>
);

export { Input, TextArea, BaseTextArea, Checkbox };