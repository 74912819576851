/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import utils from './utils';
import './banner';
import './scss/index.scss';
import bootstrap from '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import axios from 'axios';

import { logout } from './services/user';

window.bootstrap = bootstrap;
window.u = utils;
window.axios = axios;

axios.interceptors.response.use(
    function(response)
    {   
        utils.popup('hide');
        return response.data;
    }, 
    function(error)
    {
        if (error.response && error.response.status === 401)
        {
            logout();
        }

        utils.popup('hide');

        let err = 'Unknown error';

        if (error.response && error.response.data && error.response.data.msg) 
        {
            err = error.response.data.msg;
        }

        return Promise.reject(err);
    }
);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);