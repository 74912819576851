/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

const AdminPage = () => 
{
    return (
        <>
            Admin protected page
        </>
    );
}

export default AdminPage;