/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import axios from 'axios';

const userContext = React.createContext({});

const userEvents = {}

const logout = () => 
{
    sessionStorage.removeItem('user');

    window.location.href = '/provider/logout';
}

let _user = sessionStorage.getItem('user');

if (_user) 
{
    _user = JSON.parse(_user);
}

const updateProfile = (data) => 
{
    data.id = data.id || _user.id;

	return axios.post('/api/user/' + data.id, data).then((result) => 
    {
        for (let key in data) 
        {
            if (data[key] !== undefined) _user[key] = data[key];
        }

        sessionStorage.setItem('user', JSON.stringify(_user));

        userEvents.onprofileupdate && userEvents.onprofileupdate();

        return result;
    });
}

const users = {};

const getProfile = (id) => 
{
    if (id !== undefined) 
    {
        if (users[id]) 
        {
            return Promise.resolve(users[id]);
        }

        return axios.get('/api/user/profile', {params: {id}})
        .then((data) => 
        {
            users[id] = data;
            return data;
        });
    }
    else 
    {
        if (_user) 
        {
            return Promise.resolve(_user);
        }

        window.sessionStorage.removeItem('user');

        return axios.get('/provider/profile')
        .then((data) => 
        {
            _user = data;
            sessionStorage.setItem('user', JSON.stringify(data));
            return data;
        });
    }
}


export { userEvents };

export { userContext };

export { logout }

export { getProfile }

export { updateProfile }
