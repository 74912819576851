import React from 'react';
import { logout } from '../services/user';

const Logout = (props) => 
{
    return (
        <>
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={logout}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Logout;