
import utils from './Utils';

let _popupPreserveFromHiding = false;

const { el } = utils;

export default function popup(key, timeout, preserve, arg1) 
{
    const _element = el("center_hint");

    if (!_element) return;

    if (key === "hide") 
    {
        if (!_popupPreserveFromHiding) 
        {
            _element.style.display = 'none';
        }
        else 
        {
            _popupPreserveFromHiding = false;
        }
    }
    else 
    {
        var text = "";
        
        switch (key) 
        {
            case "loading":
                text = "Loading ...";
                break;
            case "downloading":
                text = "Downloading ...";
                break;
            case "saving":
                text = "Saving ...";
                break;
            case "removing":
                text = "Removing ...";
                break;
            case "uploading":
                text = "Uploading ...";
                break;
            default:
                text = key;
                break;
        }

        if (typeof timeout === 'string') 
        {
            el('center_hint_modal_body').classList.remove('justify-content-center');

            text += '<div style="font-size: 65%;margin-top: 3px;">' + timeout + '</div>';

            timeout = preserve;
            preserve = arg1;
        }
        else 
        {
            el('center_hint_modal_body').classList.add('justify-content-center');
        }

        el('center_hint_content').innerHTML = text

        if (_element.style.display !== 'block') 
        {
            _popupPreserveFromHiding = preserve

            _element.style.display = 'block';

            if (timeout !== undefined) 
            {
                setTimeout(function() {
                    if (!_popupPreserveFromHiding) 
                    {
                        _element.style.display = 'none';
                    }
                    else 
                    {
                        _popupPreserveFromHiding = false;
                    }
                }, timeout)
            }
        }
    }
}