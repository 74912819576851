
import { React, useState } from 'react';
import { addOrUpdateApp } from '../../../services/apps';
import { appInfo } from '../../../services/store';
import { Alert, Spinner, Modal } from '../../../components';

let timer = null;

const NewApplication = (props) => {

    const [show, setShow] = useState(false);

    const [appUrl, setAppUrl] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [info, setInfo] = useState({});

    const onHide = function() {
        setInfo({});
        setAppUrl("");
        setShow(false);
        setError(null);
    }

    const getApplication = async function(appUrl) 
    {
        if (appUrl.length > 6 && appUrl.startsWith('https://')) 
        {
            if (appUrl.indexOf('play.google.com') > -1 ||
                appUrl.indexOf('apps.apple.com') > -1) 
            {
                try 
                {
                    setLoading(true);
                    const response = await appInfo(appUrl);
                    setInfo(response);
                } 
                catch (e) 
                {
                    setError(e)
                }

                setLoading(false);
            } 
            else 
            {
                setError("Provide a valid Play Market or Apps Store link");
            }
        }
    }

    const handleChange = function(e) 
    {
        const value = e.target.value.trim();
        setAppUrl(value);
        setError(null);
        setInfo({});

        if (timer) 
        {
            clearTimeout(timer);
        }

        timer = setTimeout(() => getApplication(value), 500);
    }

    const handleSave = async function(e) 
    {
        setError(null);
        setSaving(true);

        try 
        {
            await addOrUpdateApp(info);
            setShow(false);
        } 
        catch (e) 
        {
            setError(e);
        }

        setSaving(false);
    }

	return (
	    <>
        <button className="btn btn-sm btn-primary shadow-sm" onClick={() => setShow(true)}>
            <i className="fas fa-plus fa-sm text-white-50" /> Add New App
        </button> 

        <Modal show={show} onHide={onHide} size="md" title="New App">
            <div className="modal-body">
                <div className="form-group mb-3">
                    <input 
                        onChange={handleChange} 
                        type="text"
                       	value={appUrl}
                        name="application"
                        className="form-control"
                        placeholder="Insert App store or Google Play URL" 
                        autoComplete="off"
                    />
                    
                </div>
                {
                    (loading || !!info.name) && <div className="card mb-3">
                    	<div className="card-body">
                    		<div className="row" style={{display: info.name ? "flex" : "none"}}>
                    			<div className="col-3">
                    				<img alt="App icon" src={info.icon} style={{width: "100%"}} />
                    			</div>
                    			<div className="col-9">
                    				<h4>{info.name}</h4>
                    				<div className="card-text">{info.developer}</div>
                    				{
                    					info.ios ? 
                    					<span><i className="fab fa-app-store-ios"></i> App Store</span> :
                    					<span><i className="fab fa-google-play"></i> Google Play</span>
                    				}
                    			</div>
                    		</div>
                    		{loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                    	</div>
                    </div>
                }

                { error && <Alert text={error}/> }

              </div>

              <div className="modal-footer">
                <button disabled={loading || saving} data-bs-dismiss="modal" className="btn btn-sm btn-secondary">Cancel</button>
                <button onClick={handleSave} disabled={loading || !info.name || saving} className="btn btn-sm btn-primary">Add App&nbsp; {saving && <Spinner />}</button>
              </div>
          </Modal>
	    </>
	);
}

export default NewApplication;