/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';

const Footer = props => (
    <>
        <footer className="sticky-footer">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; GREEN PANDA GAMES 2021</span>
                </div>
            </div>
        </footer>
    </>
);

export default Footer;