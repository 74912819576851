/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2020 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

function setValue(source, path, value) 
{
    var k = path.split('.');

    var link = source;

    for (var i = 0; i < k.length - 1; i++) 
    {
        link = link[k[i]];
    }

    link[k[k.length - 1]] = value;
}

function getValue(source, key, defaultValue)
{
    var t = typeof(source);

    if (!source || t === 'number' || t === 'string')
    {
        return defaultValue;
    }
    else if (source.hasOwnProperty(key) && source[key] !== undefined)
    {
        return source[key];
    }
    else
    {
        return defaultValue;
    }
}

export { getValue, setValue }