import { endOfDay, endOfMonth, format, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export const DateRangePicker = (props) => {
    const now = new Date();
    const yesterday = subDays(now, 1);

    const [selected, setSelected] = useState(props.selected || {});
    const [show, setShow] = useState(false);
    const calendarRef = useRef(null);

    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const changedDateRange = (period) => {
        if (period?.to) {
            period.to = endOfDay(period.to);
        }

        setSelected(period || {});
        props.onChange && props.onChange(period);
    };

    const formattedDate = (date) => {
        return format(date, 'MM/dd/yyyy');
    };

    const labelContainerHeight = 35;

    return (
        <div className={`d-flex${props.justify ? ` justify-content-${props.justify}` : ''}`}>
            <div
                onClick={() => {
                    setShow((p) => !p);
                }}
                className="form-control text-center pt-1"
                aria-expanded="false"
                style={{ height: labelContainerHeight, width: 250 }}
                role="button"
            >
                <i className="far fa-calendar-alt me-2"></i>
                {selected.from ? (
                    <>
                        {formattedDate(selected.from)} - {selected.to && formattedDate(selected.to)}
                    </>
                ) : (
                    <span className="text-muted">Select date range</span>
                )}
            </div>

            {show && (
                <div
                    ref={calendarRef}
                    style={{ marginTop: labelContainerHeight }}
                    className="d-flex position-absolute rounded border-light border bg-white"
                >
                    <div className="d-flex flex-column justify-content-between border-end border-light border-2 px-3 py-5">
                        {[
                            { title: 'Today', period: { from: startOfDay(now), to: endOfDay(now) } },
                            {
                                title: 'Yesterday',
                                period: { from: startOfDay(yesterday), to: endOfDay(yesterday) },
                            },
                            { title: 'Last 7 days', period: { from: startOfDay(subDays(now, 7)), to: now } },
                            { title: 'This month', period: { from: startOfMonth(now), to: now } },
                            {
                                title: 'Last month',
                                period: {
                                    from: startOfMonth(subMonths(now, 1)),
                                    to: endOfMonth(subMonths(now, 1)),
                                },
                            },
                        ].map(({ title, period }) => (
                            <button
                                key={title}
                                onClick={() => {
                                    changedDateRange(period);
                                }}
                                className="btn btn-outline-primary w-100"
                            >
                                {title}
                            </button>
                        ))}
                    </div>

                    <DayPicker
                        modifiersClassNames={{ selected: 'bg-primary' }}
                        modifiersStyles={{ selected: { color: 'white' } }}
                        mode="range"
                        selected={selected}
                        onSelect={(period) => {
                            changedDateRange(period);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
