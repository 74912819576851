
function el(id) 
{
    return document.getElementById(id);
}

function readFile(file, _cb_) {

    var options = {};

    if (file instanceof File) 
    {
        options = {
            name: file.name,
            format: file.name.substr(file.name.lastIndexOf(".") + 1),
            title: file.name.substr(0, file.name.lastIndexOf(".")),
            mime: file.type
        };
    }
    else 
    {
        options = {
            mime: file.type
        };
    }

    var reader = new FileReader();
    reader.onload = function(e) { _cb_(reader.result, options) }
    reader.readAsDataURL(file);
}

const Loader = 
{
    file: function(file, cb) 
    {
        if (typeof file == "string") 
        {
            file = el(file);
        }

        if (file instanceof HTMLElement) 
        {
            file = file.files;
        }

        if (file instanceof FileList) 
        {
            file = file[0];
        }

        if (!file) 
        {
            return null;
        }

        readFile(file, cb);

        return file.name;
    }
}

export default Loader;