import { React, useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import utils from 'underscore';
import { MyChart as Chart, DateRangePicker, Input, RatingDiff, Spinner } from '../../components';
import { addOrUpdateApp, appContext, getApp } from '../../services/apps';
import Reviews from './Reviews';
import Rules from './Rules';
import { subDays, startOfDay } from 'date-fns';

const periods = {
    1: '1 day ago',
    3: '3 days ago',
    7: '1 week ago',
    21: '3 weeks ago',
    30: '1 month ago',
    90: '3 months ago',
    180: '6 months ago',
    365: '1 year ago',
    1095: '3 years ago',
};

const colors = ['red', 'blue', 'green', 'black', 'orange'];

const ratings = [1, 2, 3, 4, 5];

const AppPage = (props) => {
    const { app, setApp } = useContext(appContext);
    const [newPriority, setNewPriority] = useState(null);

    const { appId } = useParams();
    const [error, setError] = useState(null);
    const [rating, setRating] = useState();
    const [data, setData] = useState({
        datasets: ratings.map((rating, i) => {
            return {
                data: [],
                label: `${rating}*`,
                borderColor: colors[i],
                pointRadius: 2,
                pointBackgroundColor: colors[i],
                borderWidth: 0.5,
                tension: 0.1,
            };
        }),
    });

    const basicDateRange = { from: startOfDay(subDays(new Date(), 30)), to: new Date() };

    const selectDataByDateRange = (period = {}) => {
        if (period.from && period.to) {
            const { from, to } = period;

            ratings.forEach((rating, i) => {
                data.datasets[i].data = app.histograms
                    .reduce((acc, { created_at, histogram }) => {
                        if (new Date(created_at) >= new Date(from) && new Date(created_at) <= new Date(to)) {
                            acc.push({ x: created_at, y: histogram[rating] });
                        }

                        return acc;
                    }, [])
                    .sort((a, b) => new Date(a.x) - new Date(b.x));
            });

            data.datasets = [...data.datasets];

            setData((p) => ({ ...p }));
        }
    };

    useEffect(() => {
        getApp(appId)
            .then((app) => {
                app.histograms = app.histograms.map(({ histogram, created_at }) => ({
                    histogram,
                    created_at: created_at.split('T')[0],
                }));

                setApp(app);

                setRating(() => {
                    if (app.previousPeriod) {
                        updateRating(app.previousPeriod, app.storeId, app.histograms);
                    } else {
                        return `Today rating: ${app.rating.toFixed(2)}`;
                    }
                });
            })
            .catch(setError);

        return () => {
            setApp(null);
        };
    }, [appId, setApp]);

    useEffect(() => {
        if (app) {
            selectDataByDateRange(basicDateRange);
        }
    }, [app]);

    const onSchangedPriority = (e) => {
        setNewPriority(Number(e.target.value));
    };

    const updatePriority = () => {
        utils.popup('loading');

        const options = {
            storeId: app.storeId,
            priority: newPriority,
        };

        addOrUpdateApp(options)
            .then(() => setNewPriority(null))
            .catch(utils.hintError);
    };

    const updateRating = (period, storeId, histograms) => {
        const calculateDate = () => {
            const date = new Date();
            date.setDate(date.getDate() - period);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        };

        const date = calculateDate();
        const histogram = histograms.find((v) => Date.parse(v.created_at) === date.getTime());

        const rating = histogram && Number(utils.avgRating(histogram.histogram));

        const updatePrevPeriod = () => {
            const options = {
                storeId: storeId,
                previousPeriod: period,
            };
            addOrUpdateApp(options);
        };

        updatePrevPeriod();

        setRating(() => {
            if (histogram) {
                return `${periods[period]} rating: ${rating.toFixed(2)}`;
            } else {
                return `${periods[period]} rating: no info for this period`;
            }
        });
    };

    return (
        <>
            {!!app && (
                <>
                    <div className="row">
                        <div className="col-12 col-sm-2 mb-2">
                            <img className="rounded-3" alt="App icon" width="100%" src={app.icon} />
                        </div>

                        <div className="col-12 col-sm-6 my-2 d-flex flex-column">
                            <h1 className="h3 text-gray-800">
                                {app.name}
                                {app.ios ? (
                                    <span>
                                        <i className="fab fa-app-store-ios"></i>
                                    </span>
                                ) : (
                                    <span>
                                        <i className="fab fa-google-play"></i>
                                    </span>
                                )}
                            </h1>
                            <h6 className="mt-2">
                                <a className="text-decoration-none" rel="noreferrer" target="_blank" href={app.url}>
                                    {app.url}
                                </a>
                            </h6>
                            <h6>{app.developer}</h6>

                            <div className="d-flex align-items-end ">
                                <Input
                                    className={'w-100'}
                                    onChange={onSchangedPriority}
                                    title="Priority"
                                    type="number"
                                    defaultValue={app.priority}
                                    id="game-priority"
                                />

                                {newPriority !== null && (
                                    <button onClick={updatePriority} className="ms-2 btn btn-sm btn-success shadow-sm">
                                        Save changes
                                    </button>
                                )}
                            </div>
                            <div className="d-flex flex-column align-items-start ">
                                <div className={'my-1'}>Get rating from customized period</div>
                                <div className={'w-100'}>
                                    <select
                                        className="form-select shadow-sm "
                                        defaultValue={app.previousPeriod}
                                        onChange={(e) => updateRating(e.target.value, app.storeId, app.histograms)}
                                    >
                                        {Object.keys(periods).map((period) => (
                                            <option value={period} key={period}>
                                                {periods[period]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 my-2">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <div className="fs-3">
                                        Rating: {app.rating.toFixed(2)}
                                        <RatingDiff app={app} previousPeriodRating={rating?.split(': ')[1]} />
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow-sm mt-3">
                                <div className="card-body text-center text-black-50">
                                    <div>{rating}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <DateRangePicker onChange={selectDataByDateRange} justify={'end'} selected={basicDateRange} />

                        {data.datasets && (
                            <Chart
                                data={{ datasets: data.datasets }}
                                type={'line'}
                                options={{
                                    interaction: {
                                        mode: 'x',
                                        intersect: false,
                                    },
                                    plugins: {
                                        tooltip: {
                                            position: 'nearest',
                                            callbacks: {
                                                title: (context) => {
                                                    const obj = app.histograms.find(
                                                        (el) => el.created_at === context[0].label
                                                    );

                                                    const avgRating = Number(utils.avgRating(obj.histogram));

                                                    return `${avgRating.toFixed(1)}* for this day`;
                                                },

                                                label: (context) => {
                                                    const obj = app.histograms.find(
                                                        (el) => el.created_at === context.label
                                                    );

                                                    const sum = Object.values(obj.histogram).reduce((acc, curr) => {
                                                        return acc + curr;
                                                    }, 0);

                                                    return `${(
                                                        (parseInt(context.formattedValue.replace(/,/g, '')) / sum) *
                                                        100
                                                    ).toFixed(1)}% of ${context.dataset.label} `;
                                                },
                                            },
                                        },
                                    },
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                        },
                                    },
                                    aspectRatio: 4,
                                }}
                                plugins={[
                                    {
                                        id: 'tooltipLine',
                                        beforeDraw: (chart) => {
                                            if (chart.tooltip._active && chart.tooltip._active.length) {
                                                const ctx = chart.ctx;
                                                ctx.save();
                                                const activePoint = chart.tooltip._active[0];
                                                ctx.beginPath();
                                                ctx.moveTo(activePoint.element.x, chart.chartArea.top);
                                                ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
                                                ctx.lineWidth = 1;
                                                ctx.strokeStyle = 'black';
                                                ctx.stroke();
                                                ctx.restore();
                                            }
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </>
            )}

            {!app && (
                <div className="mt-2 row">
                    <div className="col-sm-12 col-md-3">
                        <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" height={250} />
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="65%" height={55} />
                        <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" height={40} />
                        <Skeleton className="service-card shadow-sm m-2 mb-3 mx-1 mx-sm-2" width="85%" height={25} />

                        <Skeleton className="service-card shadow-sm mt-4 mx-1 mx-sm-2" width="65%" height={25} />
                    </div>
                </div>
            )}

            {!!app && (
                <>
                    <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
                        <li className="nav-item">
                            <button
                                className="nav-link active"
                                id="reviews-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#reviews"
                                type="button"
                                role="tab"
                                aria-controls="reviews"
                                aria-selected="true"
                            >
                                Reviews
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className="nav-link"
                                id="alerts-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#alerts"
                                type="button"
                                role="tab"
                                aria-controls="alerts"
                                aria-selected="false"
                            >
                                Alerts configuration <Spinner />
                            </button>
                        </li>
                    </ul>

                    <div className="tab-content" id="appDetailsTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="reviews"
                            role="tabpanel"
                            aria-labelledby="reviews-tab"
                        >
                            <Reviews app_id={app._id} />
                        </div>
                        <div className="tab-pane fade" id="alerts" role="tabpanel" aria-labelledby="alerts-tab">
                            <Rules app_id={app._id} />
                        </div>
                    </div>
                </>
            )}

            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </>
    );
};

export default AppPage;
