/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

let modal = null;

const Confirm = function(msg, cb) 
{
	const el = document.getElementById('confirmModal');

	if (modal ===  null) 
	{
		modal = new window.bootstrap.Modal(el);
	}

	const okB = el.querySelector('#confirmModalOkButton');

	el.querySelector('#confirmModalLabel').innerHTML = msg;

	let result = false;

	okB.onclick = function() 
	{
		result = true;
	}

	el.addEventListener('hidden.bs.modal', function() 
	{
		cb && cb(result);

	}, {once: true});

	modal.show();
}

export default Confirm;