
const Timezones = {

    "AF": {
        "tz": [
            "Asia/Kabul"
        ],
        "wtz": [
            "(UTC+04:30) Kabul"
        ]
    },
    "AL": {
        "tz": [
            "Europe/Tirane"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "DZ": {
        "tz": [
            "Africa/Algiers"
        ],
        "wtz": [
            "(UTC+01:00) West Central Africa"
        ]
    },
    "AR": {
        "tz": [
            "America/Argentina/Buenos_Aires",
            "America/Argentina/Cordoba",
            "America/Argentina/Salta",
            "America/Argentina/Jujuy",
            "America/Argentina/Tucuman",
            "America/Argentina/Catamarca",
            "America/Argentina/La_Rioja",
            "America/Argentina/San_Juan",
            "America/Argentina/Mendoza",
            "America/Argentina/San_Luis",
            "America/Argentina/Rio_Gallegos",
            "America/Argentina/Ushuaia"
        ],
        "wtz": [
            "(UTC-03:00) City of Buenos Aires"
        ]
    },
    "AM": {
        "tz": [
            "Asia/Yerevan"
        ],
        "wtz": [
            "(UTC+04:00) Yerevan"
        ]
    },
    "AU": {
        "tz": [
            "Australia/Lord_Howe",
            "Antarctica/Macquarie",
            "Australia/Hobart",
            "Australia/Currie",
            "Australia/Melbourne",
            "Australia/Sydney",
            "Australia/Broken_Hill",
            "Australia/Brisbane",
            "Australia/Lindeman",
            "Australia/Adelaide",
            "Australia/Darwin",
            "Australia/Perth",
            "Australia/Eucla"
        ],
        "wtz": [
            "(UTC+11:00) Solomon Is., New Caledonia",
            "(UTC+10:00) Hobart",
            "(UTC+10:00) Canberra, Melbourne, Sydney",
            "(UTC+09:30) Adelaide",
            "(UTC+10:00) Brisbane",
            "(UTC+09:30) Darwin",
            "(UTC+08:00) Perth"
        ]
    },
    "AT": {
        "tz": [
            "Europe/Vienna"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "AZ": {
        "tz": [
            "Asia/Baku"
        ],
        "wtz": [
            "(UTC+04:00) Baku"
        ]
    },
    "BH": {
        "tz": [
            "Asia/Bahrain"
        ],
        "wtz": [
            "(UTC+03:00) Kuwait, Riyadh"
        ]
    },
    "BD": {
        "tz": [
            "Asia/Dhaka"
        ],
        "wtz": [
            "(UTC+06:00) Dhaka"
        ]
    },
    "BY": {
        "tz": [
            "Europe/Minsk"
        ],
        "wtz": [
            "(UTC+03:00) Minsk"
        ]
    },
    "BE": {
        "tz": [
            "Europe/Brussels"
        ],
        "wtz": [
            "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
        ]
    },
    "BZ": {
        "tz": [
            "America/Belize"
        ],
        "wtz": [
            "(UTC-06:00) Central America"
        ]
    },
    "BT": {
        "tz": [
            "Asia/Thimphu"
        ],
        "wtz": [
            "(UTC+06:00) Dhaka"
        ]
    },
    "BO": {
        "tz": [
            "America/La_Paz"
        ],
        "wtz": [
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
        ]
    },
    "BA": {
        "tz": [
            "Europe/Sarajevo"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "BW": {
        "tz": [
            "Africa/Gaborone"
        ],
        "wtz": [
            "(UTC+02:00) Harare, Pretoria"
        ]
    },
    "BR": {
        "tz": [
            "America/Noronha",
            "America/Belem",
            "America/Fortaleza",
            "America/Recife",
            "America/Araguaina",
            "America/Maceio",
            "America/Bahia",
            "America/Sao_Paulo",
            "America/Campo_Grande",
            "America/Cuiaba",
            "America/Santarem",
            "America/Porto_Velho",
            "America/Boa_Vista",
            "America/Manaus",
            "America/Eirunepe",
            "America/Rio_Branco"
        ],
        "wtz": [
            "(UTC-02:00) Coordinated Universal Time-02",
            "(UTC-03:00) Cayenne, Fortaleza",
            "(UTC-03:00) Salvador",
            "(UTC-03:00) Brasilia",
            "(UTC-04:00) Cuiaba",
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        ]
    },
    "BN": {
        "tz": [
            "Asia/Brunei"
        ],
        "wtz": [
            "(UTC+08:00) Kuala Lumpur, Singapore"
        ]
    },
    "BG": {
        "tz": [
            "Europe/Sofia"
        ],
        "wtz": [
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        ]
    },
    "KH": {
        "tz": [
            "Asia/Phnom_Penh"
        ],
        "wtz": [
            "(UTC+07:00) Bangkok, Hanoi, Jakarta"
        ]
    },
    "CM": {
        "tz": [
            "Africa/Douala"
        ],
        "wtz": [
            "(UTC+01:00) West Central Africa"
        ]
    },
    "CA": {
        "tz": [
            "America/St_Johns",
            "America/Halifax",
            "America/Glace_Bay",
            "America/Moncton",
            "America/Goose_Bay",
            "America/Blanc-Sablon",
            "America/Toronto",
            "America/Nipigon",
            "America/Thunder_Bay",
            "America/Iqaluit",
            "America/Pangnirtung",
            "America/Atikokan",
            "America/Winnipeg",
            "America/Rainy_River",
            "America/Resolute",
            "America/Rankin_Inlet",
            "America/Regina",
            "America/Swift_Current",
            "America/Edmonton",
            "America/Cambridge_Bay",
            "America/Yellowknife",
            "America/Inuvik",
            "America/Creston",
            "America/Dawson_Creek",
            "America/Fort_Nelson",
            "America/Vancouver",
            "America/Whitehorse",
            "America/Dawson"
        ],
        "wtz": [
            "(UTC-03:30) Newfoundland",
            "(UTC-04:00) Atlantic Time (Canada)",
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
            "(UTC-05:00) Eastern Time (US & Canada)",
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
            "(UTC-06:00) Central Time (US & Canada)",
            "(UTC-06:00) Saskatchewan",
            "(UTC-07:00) Mountain Time (US & Canada)",
            "(UTC-07:00) Arizona",
            "(UTC-08:00) Pacific Time (US & Canada)"
        ]
    },
    "CL": {
        "tz": [
            "America/Santiago",
            "Pacific/Easter"
        ],
        "wtz": [
            "(UTC-04:00) Santiago",
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        ]
    },
    "CN": {
        "tz": [
            "Asia/Shanghai",
            "Asia/Urumqi"
        ],
        "wtz": [
            "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
            "(UTC+06:00) Astana"
        ]
    },
    "CO": {
        "tz": [
            "America/Bogota"
        ],
        "wtz": [
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        ]
    },
    "CD": {
        "tz": [
            "Africa/Kinshasa",
            "Africa/Lubumbashi"
        ],
        "wtz": [
            "(UTC+01:00) West Central Africa",
            "(UTC+02:00) Harare, Pretoria"
        ]
    },
    "CR": {
        "tz": [
            "America/Costa_Rica"
        ],
        "wtz": [
            "(UTC-06:00) Central America"
        ]
    },
    "CI": {
        "tz": [
            "Africa/Abidjan"
        ],
        "wtz": [
            "(UTC+00:00) Monrovia, Reykjavik"
        ]
    },
    "HR": {
        "tz": [
            "Europe/Zagreb"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "CU": {
        "tz": [
            "America/Havana"
        ],
        "wtz": [
            "(UTC-05:00) Eastern Time (US & Canada)"
        ]
    },
    "CZ": {
        "tz": [
            "Europe/Prague"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "DK": {
        "tz": [
            "Europe/Copenhagen"
        ],
        "wtz": [
            "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
        ]
    },
    "DJ": {
        "tz": [
            "Africa/Djibouti"
        ],
        "wtz": [
            "(UTC+03:00) Nairobi"
        ]
    },
    "DO": {
        "tz": [
            "America/Santo_Domingo"
        ],
        "wtz": [
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
        ]
    },
    "EC": {
        "tz": [
            "America/Guayaquil",
            "Pacific/Galapagos"
        ],
        "wtz": [
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
            "(UTC-06:00) Central America"
        ]
    },
    "EG": {
        "tz": [
            "Africa/Cairo"
        ],
        "wtz": [
            "(UTC+02:00) Cairo"
        ]
    },
    "SV": {
        "tz": [
            "America/El_Salvador"
        ],
        "wtz": [
            "(UTC-06:00) Central America"
        ]
    },
    "ER": {
        "tz": [
            "Africa/Asmara"
        ],
        "wtz": [
            "(UTC+03:00) Nairobi"
        ]
    },
    "EE": {
        "tz": [
            "Europe/Tallinn"
        ],
        "wtz": [
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        ]
    },
    "ET": {
        "tz": [
            "Africa/Addis_Ababa"
        ],
        "wtz": [
            "(UTC+03:00) Nairobi"
        ]
    },
    "FO": {
        "tz": [
            "Atlantic/Faroe"
        ],
        "wtz": [
            "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
        ]
    },
    "FI": {
        "tz": [
            "Europe/Helsinki"
        ],
        "wtz": [
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        ]
    },
    "FR": {
        "tz": [
            "Europe/Paris"
        ],
        "wtz": [
            "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
        ]
    },
    "GE": {
        "tz": [
            "Asia/Tbilisi"
        ],
        "wtz": [
            "(UTC+04:00) Tbilisi"
        ]
    },
    "DE": {
        "tz": [
            "Europe/Berlin",
            "Europe/Busingen"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "GR": {
        "tz": [
            "Europe/Athens"
        ],
        "wtz": [
            "(UTC+02:00) Athens, Bucharest"
        ]
    },
    "GL": {
        "tz": [
            "America/Godthab",
            "America/Danmarkshavn",
            "America/Scoresbysund",
            "America/Thule"
        ],
        "wtz": [
            "(UTC-03:00) Greenland",
            "UTC",
            "(UTC-01:00) Azores",
            "(UTC-04:00) Atlantic Time (Canada)"
        ]
    },
    "GT": {
        "tz": [
            "America/Guatemala"
        ],
        "wtz": [
            "(UTC-06:00) Central America"
        ]
    },
    "HT": {
        "tz": [
            "America/Port-au-Prince"
        ],
        "wtz": [
            "(UTC-05:00) Eastern Time (US & Canada)"
        ]
    },
    "HN": {
        "tz": [
            "America/Tegucigalpa"
        ],
        "wtz": [
            "(UTC-06:00) Central America"
        ]
    },
    "HK": {
        "tz": [
            "Asia/Hong_Kong"
        ],
        "wtz": [
            "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
        ]
    },
    "HU": {
        "tz": [
            "Europe/Budapest"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "IS": {
        "tz": [
            "Atlantic/Reykjavik"
        ],
        "wtz": [
            "(UTC+00:00) Monrovia, Reykjavik"
        ]
    },
    "IN": {
        "tz": [
            "Asia/Kolkata"
        ],
        "wtz": [
            "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
        ]
    },
    "ID": {
        "tz": [
            "Asia/Jakarta",
            "Asia/Pontianak",
            "Asia/Makassar",
            "Asia/Jayapura"
        ],
        "wtz": [
            "(UTC+07:00) Bangkok, Hanoi, Jakarta",
            "(UTC+08:00) Kuala Lumpur, Singapore",
            "(UTC+09:00) Osaka, Sapporo, Tokyo"
        ]
    },
    "IR": {
        "tz": [
            "Asia/Tehran"
        ],
        "wtz": [
            "(UTC+03:30) Tehran"
        ]
    },
    "IQ": {
        "tz": [
            "Asia/Baghdad"
        ],
        "wtz": [
            "(UTC+03:00) Baghdad"
        ]
    },
    "IE": {
        "tz": [
            "Europe/Dublin"
        ],
        "wtz": [
            "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
        ]
    },
    "IL": {
        "tz": [
            "Asia/Jerusalem"
        ],
        "wtz": [
            "(UTC+02:00) Jerusalem"
        ]
    },
    "IT": {
        "tz": [
            "Europe/Rome"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "JM": {
        "tz": [
            "America/Jamaica"
        ],
        "wtz": [
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        ]
    },
    "JP": {
        "tz": [
            "Asia/Tokyo"
        ],
        "wtz": [
            "(UTC+09:00) Osaka, Sapporo, Tokyo"
        ]
    },
    "JO": {
        "tz": [
            "Asia/Amman"
        ],
        "wtz": [
            "(UTC+02:00) Amman"
        ]
    },
    "KZ": {
        "tz": [
            "Asia/Almaty",
            "Asia/Qyzylorda",
            "Asia/Aqtobe",
            "Asia/Aqtau",
            "Asia/Oral"
        ],
        "wtz": [
            "(UTC+06:00) Astana",
            "(UTC+05:00) Ashgabat, Tashkent"
        ]
    },
    "KE": {
        "tz": [
            "Africa/Nairobi"
        ],
        "wtz": [
            "(UTC+03:00) Nairobi"
        ]
    },
    "KR": {
        "tz": [
            "Asia/Seoul"
        ],
        "wtz": [
            "(UTC+09:00) Seoul"
        ]
    },
    "KW": {
        "tz": [
            "Asia/Kuwait"
        ],
        "wtz": [
            "(UTC+03:00) Kuwait, Riyadh"
        ]
    },
    "KG": {
        "tz": [
            "Asia/Bishkek"
        ],
        "wtz": [
            "(UTC+06:00) Astana"
        ]
    },
    "LA": {
        "tz": [
            "Asia/Vientiane"
        ],
        "wtz": [
            "(UTC+07:00) Bangkok, Hanoi, Jakarta"
        ]
    },
    "LV": {
        "tz": [
            "Europe/Riga"
        ],
        "wtz": [
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        ]
    },
    "LB": {
        "tz": [
            "Asia/Beirut"
        ],
        "wtz": [
            "(UTC+02:00) Beirut"
        ]
    },
    "LY": {
        "tz": [
            "Africa/Tripoli"
        ],
        "wtz": [
            "(UTC+02:00) Tripoli"
        ]
    },
    "LI": {
        "tz": [
            "Europe/Vaduz"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "LT": {
        "tz": [
            "Europe/Vilnius"
        ],
        "wtz": [
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        ]
    },
    "LU": {
        "tz": [
            "Europe/Luxembourg"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "MO": {
        "tz": [
            "Asia/Macau"
        ],
        "wtz": [
            "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
        ]
    },
    "MK": {
        "tz": [
            "Europe/Skopje"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "MY": {
        "tz": [
            "Asia/Kuala_Lumpur",
            "Asia/Kuching"
        ],
        "wtz": [
            "(UTC+08:00) Kuala Lumpur, Singapore"
        ]
    },
    "MV": {
        "tz": [
            "Indian/Maldives"
        ],
        "wtz": [
            "(UTC+05:00) Ashgabat, Tashkent"
        ]
    },
    "ML": {
        "tz": [
            "Africa/Bamako"
        ],
        "wtz": [
            "(UTC+00:00) Monrovia, Reykjavik"
        ]
    },
    "MT": {
        "tz": [
            "Europe/Malta"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "MX": {
        "tz": [
            "America/Mexico_City",
            "America/Cancun",
            "America/Merida",
            "America/Monterrey",
            "America/Matamoros",
            "America/Mazatlan",
            "America/Chihuahua",
            "America/Ojinaga",
            "America/Hermosillo",
            "America/Tijuana",
            "America/Bahia_Banderas"
        ],
        "wtz": [
            "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
            "(UTC-05:00) Chetumal",
            "(UTC-06:00) Central Time (US & Canada)",
            "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
            "(UTC-07:00) Mountain Time (US & Canada)",
            "(UTC-07:00) Arizona",
            "(UTC-08:00) Pacific Time (US & Canada)"
        ]
    },
    "MD": {
        "tz": [
            "Europe/Chisinau"
        ],
        "wtz": [
            "(UTC+02:00) Chisinau"
        ]
    },
    "MC": {
        "tz": [
            "Europe/Monaco"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "MN": {
        "tz": [
            "Asia/Ulaanbaatar",
            "Asia/Hovd",
            "Asia/Choibalsan"
        ],
        "wtz": [
            "(UTC+08:00) Ulaanbaatar"
        ]
    },
    "ME": {
        "tz": [
            "Europe/Podgorica"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "MA": {
        "tz": [
            "Africa/Casablanca"
        ],
        "wtz": [
            "(UTC+00:00) Casablanca"
        ]
    },
    "MM": {
        "tz": [
            "Asia/Rangoon"
        ],
        "wtz": [
            "(UTC+06:30) Yangon (Rangoon)"
        ]
    },
    "NP": {
        "tz": [
            "Asia/Kathmandu"
        ],
        "wtz": [
            "(UTC+05:45) Kathmandu"
        ]
    },
    "NL": {
        "tz": [
            "Europe/Amsterdam"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "NZ": {
        "tz": [
            "Pacific/Auckland",
            "Pacific/Chatham"
        ],
        "wtz": [
            "(UTC+12:00) Auckland, Wellington"
        ]
    },
    "NI": {
        "tz": [
            "America/Managua"
        ],
        "wtz": [
            "(UTC-06:00) Central America"
        ]
    },
    "NG": {
        "tz": [
            "Africa/Lagos"
        ],
        "wtz": [
            "(UTC+01:00) West Central Africa"
        ]
    },
    "NO": {
        "tz": [
            "Europe/Oslo"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "OM": {
        "tz": [
            "Asia/Muscat"
        ],
        "wtz": [
            "(UTC+04:00) Abu Dhabi, Muscat"
        ]
    },
    "PK": {
        "tz": [
            "Asia/Karachi"
        ],
        "wtz": [
            "(UTC+05:00) Islamabad, Karachi"
        ]
    },
    "PA": {
        "tz": [
            "America/Panama"
        ],
        "wtz": [
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        ]
    },
    "PY": {
        "tz": [
            "America/Asuncion"
        ],
        "wtz": [
            "(UTC-04:00) Asuncion"
        ]
    },
    "PE": {
        "tz": [
            "America/Lima"
        ],
        "wtz": [
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        ]
    },
    "PH": {
        "tz": [
            "Asia/Manila"
        ],
        "wtz": [
            "(UTC+08:00) Kuala Lumpur, Singapore"
        ]
    },
    "PL": {
        "tz": [
            "Europe/Warsaw"
        ],
        "wtz": [
            "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
        ]
    },
    "PT": {
        "tz": [
            "Europe/Lisbon",
            "Atlantic/Madeira",
            "Atlantic/Azores"
        ],
        "wtz": [
            "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
            "(UTC-01:00) Azores"
        ]
    },
    "PR": {
        "tz": [
            "America/Puerto_Rico"
        ],
        "wtz": [
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
        ]
    },
    "QA": {
        "tz": [
            "Asia/Qatar"
        ],
        "wtz": [
            "(UTC+03:00) Kuwait, Riyadh"
        ]
    },
    "RE": {
        "tz": [
            "Indian/Reunion"
        ],
        "wtz": [
            "(UTC+04:00) Port Louis"
        ]
    },
    "RO": {
        "tz": [
            "Europe/Bucharest"
        ],
        "wtz": [
            "(UTC+02:00) Athens, Bucharest"
        ]
    },
    "RU": {
        "tz": [
            "Europe/Kaliningrad",
            "Europe/Moscow",
            "Europe/Simferopol",
            "Europe/Volgograd",
            "Europe/Astrakhan",
            "Europe/Samara",
            "Europe/Ulyanovsk",
            "Asia/Yekaterinburg",
            "Asia/Omsk",
            "Asia/Novosibirsk",
            "Asia/Barnaul",
            "Asia/Novokuznetsk",
            "Asia/Krasnoyarsk",
            "Asia/Irkutsk",
            "Asia/Chita",
            "Asia/Yakutsk",
            "Asia/Khandyga",
            "Asia/Vladivostok",
            "Asia/Ust-Nera",
            "Asia/Magadan",
            "Asia/Sakhalin",
            "Asia/Srednekolymsk",
            "Asia/Kamchatka",
            "Asia/Anadyr"
        ],
        "wtz": [
            "(UTC+02:00) Kaliningrad",
            "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
            "(UTC+04:00) Izhevsk, Samara",
            "(UTC+05:00) Ekaterinburg",
            "(UTC+07:00) Novosibirsk",
            "(UTC+07:00) Krasnoyarsk",
            "(UTC+08:00) Irkutsk",
            "(UTC+09:00) Yakutsk",
            "(UTC+10:00) Vladivostok",
            "(UTC+11:00) Magadan",
            "(UTC+11:00) Chokurdakh",
            "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky"
        ]
    },
    "RW": {
        "tz": [
            "Africa/Kigali"
        ],
        "wtz": [
            "(UTC+02:00) Harare, Pretoria"
        ]
    },
    "SA": {
        "tz": [
            "Asia/Riyadh"
        ],
        "wtz": [
            "(UTC+03:00) Kuwait, Riyadh"
        ]
    },
    "SN": {
        "tz": [
            "Africa/Dakar"
        ],
        "wtz": [
            "(UTC+00:00) Monrovia, Reykjavik"
        ]
    },
    "RS": {
        "tz": [
            "Europe/Belgrade"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "SG": {
        "tz": [
            "Asia/Singapore"
        ],
        "wtz": [
            "(UTC+08:00) Kuala Lumpur, Singapore"
        ]
    },
    "SK": {
        "tz": [
            "Europe/Bratislava"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "SI": {
        "tz": [
            "Europe/Ljubljana"
        ],
        "wtz": [
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        ]
    },
    "SO": {
        "tz": [
            "Africa/Mogadishu"
        ],
        "wtz": [
            "(UTC+03:00) Nairobi"
        ]
    },
    "ZA": {
        "tz": [
            "Africa/Johannesburg"
        ],
        "wtz": [
            "(UTC+02:00) Harare, Pretoria"
        ]
    },
    "ES": {
        "tz": [
            "Europe/Madrid",
            "Africa/Ceuta",
            "Atlantic/Canary"
        ],
        "wtz": [
            "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
            "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
        ]
    },
    "LK": {
        "tz": [
            "Asia/Colombo"
        ],
        "wtz": [
            "(UTC+05:30) Sri Jayawardenepura"
        ]
    },
    "SE": {
        "tz": [
            "Europe/Stockholm"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "CH": {
        "tz": [
            "Europe/Zurich"
        ],
        "wtz": [
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        ]
    },
    "SY": {
        "tz": [
            "Asia/Damascus"
        ],
        "wtz": [
            "(UTC+02:00) Damascus"
        ]
    },
    "TW": {
        "tz": [
            "Asia/Taipei"
        ],
        "wtz": [
            "(UTC+08:00) Taipei"
        ]
    },
    "TJ": {
        "tz": [
            "Asia/Dushanbe"
        ],
        "wtz": [
            "(UTC+05:00) Ashgabat, Tashkent"
        ]
    },
    "TH": {
        "tz": [
            "Asia/Bangkok"
        ],
        "wtz": [
            "(UTC+07:00) Bangkok, Hanoi, Jakarta"
        ]
    },
    "TT": {
        "tz": [
            "America/Port_of_Spain"
        ],
        "wtz": [
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
        ]
    },
    "TN": {
        "tz": [
            "Africa/Tunis"
        ],
        "wtz": [
            "(UTC+01:00) West Central Africa"
        ]
    },
    "TR": {
        "tz": [
            "Europe/Istanbul"
        ],
        "wtz": [
            "(UTC+02:00) Istanbul"
        ]
    },
    "TM": {
        "tz": [
            "Asia/Ashgabat"
        ],
        "wtz": [
            "(UTC+05:00) Ashgabat, Tashkent"
        ]
    },
    "UA": {
        "tz": [
            "Europe/Kiev",
            "Europe/Uzhgorod",
            "Europe/Zaporozhye"
        ],
        "wtz": [
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        ]
    },
    "AE": {
        "tz": [
            "Asia/Dubai"
        ],
        "wtz": [
            "(UTC+04:00) Abu Dhabi, Muscat"
        ]
    },
    "GB": {
        "tz": [
            "Europe/London"
        ],
        "wtz": [
            "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
        ]
    },
    "US": {
        "tz": [
            "America/New_York",
            "America/Detroit",
            "America/Kentucky/Louisville",
            "America/Kentucky/Monticello",
            "America/Indiana/Indianapolis",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Indiana/Marengo",
            "America/Indiana/Petersburg",
            "America/Indiana/Vevay",
            "America/Chicago",
            "America/Indiana/Tell_City",
            "America/Indiana/Knox",
            "America/Menominee",
            "America/North_Dakota/Center",
            "America/North_Dakota/New_Salem",
            "America/North_Dakota/Beulah",
            "America/Denver",
            "America/Boise",
            "America/Phoenix",
            "America/Los_Angeles",
            "America/Anchorage",
            "America/Juneau",
            "America/Sitka",
            "America/Metlakatla",
            "America/Yakutat",
            "America/Nome",
            "America/Adak",
            "Pacific/Honolulu"
        ],
        "wtz": [
            "(UTC-05:00) Eastern Time (US & Canada)",
            "(UTC-05:00) Indiana (East)",
            "(UTC-06:00) Central Time (US & Canada)",
            "(UTC-07:00) Mountain Time (US & Canada)",
            "(UTC-07:00) Arizona",
            "(UTC-08:00) Pacific Time (US & Canada)",
            "(UTC-09:00) Alaska",
            "(UTC-10:00) Hawaii"
        ]
    },
    "UY": {
        "tz": [
            "America/Montevideo"
        ],
        "wtz": [
            "(UTC-03:00) Montevideo"
        ]
    },
    "UZ": {
        "tz": [
            "Asia/Samarkand",
            "Asia/Tashkent"
        ],
        "wtz": [
            "(UTC+05:00) Ashgabat, Tashkent"
        ]
    },
    "VE": {
        "tz": [
            "America/Caracas"
        ],
        "wtz": [
            "(UTC-04:00) Caracas"
        ]
    },
    "VN": {
        "tz": [
            "Asia/Ho_Chi_Minh"
        ],
        "wtz": [
            "(UTC+07:00) Bangkok, Hanoi, Jakarta"
        ]
    },
    "YE": {
        "tz": [
            "Asia/Aden"
        ],
        "wtz": [
            "(UTC+03:00) Kuwait, Riyadh"
        ]
    },
    "ZW": {
        "tz": [
            "Africa/Harare"
        ],
        "wtz": [
            "(UTC+02:00) Harare, Pretoria"
        ]
    }
}

const TimezonesArray = [];

for (let key in Timezones) 
{
	var wtz = Timezones[key].wtz;

	for (let tz of wtz) 
	{
		if (TimezonesArray.indexOf(tz) === -1) TimezonesArray.push(tz);
	}
}

export { Timezones, TimezonesArray };