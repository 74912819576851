
import { useEffect } from 'react';

// (function($)
// {
//     $(window).ready(function() 
//     {
//     // Scroll to top button appear
//     $('#page-content').on('scroll', function()
//     {
//         var scrollDistance = $(this).scrollTop();
//         if (scrollDistance > 100)
//         {
//             $('.scroll-to-top').fadeIn();
//         }
//         else
//         {
//             $('.scroll-to-top').fadeOut();
//         }
//     });

//     // Smooth scrolling using jQuery easing
//     $('a.scroll-to-top').on('click', function(e)
//     {
//         $('#page-content').stop().animate(
//         {
//             scrollTop: $('#page-content').offset().top
//         }, 1000, 'easeInOutExpo');

//         e.preventDefault();
//     });

//     })

// })(window.jQuery);

const TWEEN = window.TWEEN;

const ScrollToTop = props => {

    useEffect(() => 
    {
        const pageContent = document.getElementById('page-content');
        const scrollToTop = document.getElementById('scrollToTopButton');

        scrollToTop.onclick = function() 
        {
            new TWEEN.Tween(pageContent)
            .to({scrollTop: 0}, 1000)
            .easing(TWEEN.Easing.Exponential.InOut)
            .start();
        }

        pageContent.onscroll = function() 
        {
            const scrollDistance = pageContent.scrollTop;

            if (scrollDistance > 100)
            {
                scrollToTop.style.display = 'block';
                scrollToTop.classList.add('show');
            }
            else
            {
                scrollToTop.classList.remove('show');
                // scrollToTop.style.display = 'none';
            }
        }

    }, []);

    return (
        <div id="scrollToTopButton" role="button" className="scroll-to-top rounded fade">
            <i className="fas fa-angle-up"></i>
        </div>
    );
}

export { ScrollToTop };