import utils from "underscore";

const RatingDiff = ({ app, previousPeriodRating }) => {
  let diff = utils.diffPercentageIncrease(
    app.rating.toFixed(2),
    previousPeriodRating
  );

  diff = Math.round(diff * 100) / 100;
  return (
    <span
      className={
        "fs-6 fw-bold text-" +
        (diff < 0 ? "danger" : diff > 0 ? "success" : "dark d-none")
      }
    >
      <i className={"fas fa-level-" + (diff > 0 ? "up" : "down") + "-alt"} />{" "}
      {(diff > 0 ? "+" : "") + diff.toFixed(1)}%
    </span>
  );
};

export { RatingDiff };
