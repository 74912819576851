
import React from 'react';

const Loading = props => 
{
	return (
	    <div id='loadingPage' className="flex-column vw-100 vh-100 justify-content-center align-items-center">
			<div id="loader">
				<div id="loader-body">

				</div>
			</div>
			<div className="text-loading mt-5">L o a d i n g . . .</div>
	    </div>
	);
};

export default Loading;

// <div class="gooey">
//   <span class="dot"></span>
//   <div class="dots">
//     <span></span>
//     <span></span>
//     <span></span>
//   </div>
// </div>

			
// <div className="container-loader">
// 	<div className="loader"></div>
// </div>