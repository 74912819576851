
import { useMemo, useEffect } from 'react'
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table'

const Table = (props) => {

    const { columns, data, fetchData, loading } = props;

    const filterTypes = useMemo(() => ({

        customTextFilter: (rows, columnIds, filterValue) => {

            const search = String(filterValue).toLowerCase();

            if (!search) return rows;

            rows = rows.filter( row => {

                if (row.original.globalFilter) 
                {
                    return String(row.original.globalFilter).toLowerCase().includes(search);
                }

                return columnIds.some(id => {
                    return String(row.values[id]).toLowerCase().includes(search)
                })
            });

            return rows;
        }
    }), [])

    const options = {
        columns,
        data,
        initialState: { 
            pageSize: props.pageSize || 10,
            pageIndex: 0,
            sortBy: props.sortBy || [] 
        },
        disableSortRemove: true,
        filterTypes,
        autoResetGlobalFilter: false,
        globalFilter: 'customTextFilter',
        manualPagination: !!fetchData,
        manualSortBy: !!fetchData,
        manualGlobalFilter: !!fetchData

        // autoResetPage: false,
        // autoResetExpanded: false,
        // autoResetGroupBy: false,
        // autoResetSelectedRows: false,
        // autoResetSortBy: false,
        // autoResetFilters: false,
        // autoResetRowState: false,

        // globalFilter: function(rows, columnIds, globalFilterValue) {
        //     console.log(rows);
        //     console.log(columnIds);
        //     console.log(globalFilterValue);

        //     return [];
        // }
    }

    if (!!fetchData) 
    {
        options.pageCount = props.pageCount;
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        // pageOptions,
        pageCount,
        rows,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy, globalFilter },
        // preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        options, 
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // useEffect(() => 
    // {
    //     gotoPage(0);
    // }, [data]);

    const onFetchDataDebounced = useAsyncDebounce(fetchData, 100);

    useEffect(() => 
    {
        gotoPage(0);

    }, [fetchData, gotoPage]);

    useEffect(() => {

        fetchData && onFetchDataDebounced(pageIndex, pageSize, globalFilter, sortBy);

    }, [fetchData, onFetchDataDebounced, pageIndex, pageSize, globalFilter, sortBy]);

    const totalCount = props.totalCount || rows.length;

    let lastShowedItem = pageIndex * pageSize + pageSize;

    if (lastShowedItem > totalCount) lastShowedItem = totalCount;

    let firstShowedItem = pageIndex * pageSize + 1;

    if (firstShowedItem > totalCount) firstShowedItem = totalCount;

    const onClick = (value) => 
    {
        props.onClick && props.onClick(value);
    };

    const onSearchChange = useAsyncDebounce(value => {
        
        if (value) value = value.trim();
        else value = undefined;

        setGlobalFilter(value);

    }, 300)

    // console.log('Table total rows:' + totalCount);

    const buttons = useMemo(() => 
    {
        const _buttons = [];

        const pageBtnClick = (e) => 
        {
            const newIndex = Number(e.target.getAttribute("data-idx"));

            gotoPage(newIndex);
        }

        const bt = [];

        if (pageCount > 7) 
        {
            const last = pageCount - 1;

            let start = 1;
            let end = last;

            if (pageIndex > 3) 
            {
                if (pageIndex < last - 3) 
                {
                    end = pageIndex + 3;
                    start = pageIndex - 2;
                }
                else 
                {
                    start = last - 5;
                }
            }
            else 
            {
                end = 6;
            }

            bt.push(0);

            for (let i = start; i < end; i++) 
            {
                bt.push(i);
            }

            bt.push(last);

            if (pageIndex > 3) bt[1] = "start";

            if (pageIndex < last - 3) bt[5] = "end";

            for (let i = 0; i < bt.length; i++) 
            {
                const index = bt[i];

                let className = "page-item";
                if (index === pageIndex) className += " active";

                if (index === "start" || index === "end") _buttons.push(<li key={"page_" + index} className="page-item disabled"><div role="button" data-idx={index} className="page-link">...</div></li>);
                else _buttons.push(<li key={"page_" + i} className={className}><div role="button" data-idx={index} onClick={pageBtnClick} className="page-link">{ index + 1 }</div></li>)
            }
        }
        else 
        {
            for (let index = 0; index < pageCount; index++) 
            {
                let className = "page-item";
                if (index === pageIndex) className += " active";

                _buttons.push(<li key={"page_" + index} className={className}><div role="button" data-idx={index} onClick={pageBtnClick} className="page-link">{ index + 1 }</div></li>)
            }
        }

        return _buttons;
        
    }, [pageIndex, pageCount, gotoPage]);

    return (
        <div className={"datatable" + (loading ? " disabled" : "")}>

            { props.header !== false && <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="datatable-size">
                            <label>
                                Show <select className="form-select form-select-sm" value={pageSize} onChange={ e => setPageSize(Number(e.target.value)) }>
                                    {[10, 20, 30, 40, 50, 100].map( pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))}
                                </select> entries
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="datatable-filter">
                            Search:
                            <input type="search" onChange={(e) => {
                                const value = e.target.value;
                                onSearchChange(value);
                            }} className="form-control form-control-sm" placeholder="" />
                        </div>
                    </div>
                </div>
            }

            <div className="row datatable-row">
                <div className="col-sm-12">
                    <table {...getTableProps({ className: "table w-100" + (props.className ? " " + props.className : "") })}>
                        <thead>
                            {
                                headerGroups.map( headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map( column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps({style: {width: column.size && column.size + "px"}, className: column.canSort ? 'sorting' + (column.isSorted ? column.isSortedDesc ? ' desc' : ' asc' : '') : ''}))}>{ column.render('Header') }</th>
                                        ))}
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr onClick={() => onClick(row.original)} {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps({scope: 'row', className: cell.column.cellClass})}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            { props.footer !== false && <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="datatable-info" role="status" aria-live="polite">Showing {firstShowedItem} to {lastShowedItem} of {totalCount} entries</div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <ul className="pagination datatable-pagination">
                            <li className={"page-item" + (!canPreviousPage ? " disabled" : "")}>
                                <div role="button" className="page-link" onClick={() => previousPage()} aria-disabled="true">Previous</div>
                            </li>
                            {
                                buttons
                            }
                            <li className={"page-item" + (!canNextPage ? " disabled" : "")}>
                                <div role="button" className="page-link" onClick={() => nextPage()}>Next</div>
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}


export { Table };