
import React from 'react';

const ConfirmModal = (props) => 
{
    return (
        <>
            <div className="modal fade" id="confirmModal" data-bs-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog"  role="document">
                    <div className="modal-content">
                    
                        <div className="modal-header">
                            <div className="modal-title text-dark" id="confirmModalLabel">Confirm Action</div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    
                        
                        <div className="modal-footer">
                            <button id="confirmModalCancelButton" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button id="confirmModalOkButton" className="btn btn-primary" data-bs-dismiss="modal" type="button">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmModal;