
import { useContext } from 'react';
import { userContext, updateProfile } from '../services/user';
import { Checkbox } from '../components';
import utils from 'underscore';

const AppPage = (props) => {

	const { notifications } = useContext(userContext);

	const handleChange = (e) => 
	{
		const target = e.target;
	    const value = target.checked;
	    const name = target.name;

	    notifications[name] = value;

	    utils.popup('loading');

	    updateProfile({ notifications }).then(() => 
		{
			utils.popup('hide');
			utils.hint('Notification settings has been updated', {alert: 'success'});
		}).catch(utils.hintError);
	}

	return (
	    <>
	    	<h3 className="h3 mb-4 text-gray-800">Notifications</h3>

	       	<Checkbox title="Weekly report" defaultValue={!!notifications["weekly_report"]} name="weekly_report" onChange={handleChange} />
	       	<Checkbox title="Application alerts" defaultValue={!!notifications["apps_alerts"]} name="apps_alerts" onChange={handleChange} />
	    </>
	);
}

export default AppPage;